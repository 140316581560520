import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IEarnTransaction } from '@/types/account/earn.interface'

import formatId from '@/utils/formatId'

import { msatsToSats } from '@/utils'

export const columns: ColumnDef<IEarnTransaction>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'account',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account' />
    },
    cell: ({ row }) => {
      return <UserDetailsModal fromOtherPage user={row.original.account} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'account.email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.account.email)
            toast.success('Email copied to clipboard')
          }}
          className='text-foreground/50'
        >
          {row.original.account.email}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'amount_msats',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Amount' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-purple400'>
          {msatsToSats(row.original.amount_msats).toString()} sats
        </p>
      )
    }
  },
  {
    accessorKey: 'invoice',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Invoice' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.invoice || 'No Invoice')
            toast.success('Invoice Copied to clipboard')
          }}
          className='text-foreground/50'
        >
          {row.original.invoice || 'No Invoice'}
        </button>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return <p>{new Date(row.original.created_at).toLocaleString()}</p>
    }
  }
]
