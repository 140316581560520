import MetricsCards from '.'
import { useQuery } from '@tanstack/react-query'

import { IEarnMetrics, IMetricCard } from '@/types/metrics/metrics.interface'

import api from '@/api'
import { formatNumber, msatsToSats } from '@/utils'

const formatEarnMetrics = (data: IEarnMetrics) => {
  return [
    {
      title: 'Earn Wallet Balance',
      value: `$${formatNumber(data.total_balance_usd)}`,
      subtitle: `${msatsToSats(data.total_balance_msats)} sats`,
      className: 'md:col-span-3',
      valueColor: 'purple'
    },
    {
      title: 'Total Claimable',
      value: `$${formatNumber(data.total_earned_unclaimed_usd)}`,
      subtitle: `${msatsToSats(data.total_earned_unclaimed_msats)} sats`,
      className: 'md:col-span-3',
      valueColor: 'purple'
    },
    {
      title: 'Total Future Payout',
      value: `$${formatNumber(data.total_future_payout_usd)}`,
      subtitle: `${msatsToSats(data.total_future_payout_msats)} sats`,
      className: 'md:col-span-2',
      valueColor: 'blue'
    },
    {
      title: 'Total Daily Earn Rate',
      value: `$${formatNumber(data.total_daily_earn_rate_usd)}`,
      subtitle: `${msatsToSats(data.total_daily_earn_rate_msats)} sats`,
      className: 'md:col-span-2',
      valueColor: 'green'
    },
    {
      title: 'Total Paid Out',
      value: `$${formatNumber(data.total_paid_out_usd)}`,
      subtitle: `${msatsToSats(data.total_paid_out_msats)} sats`,
      className: 'md:col-span-2',
      valueColor: 'orange'
    }
  ] as IMetricCard[]
}

const EarnMetrics = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['metricsEarn'],
    queryFn: async () => await api.get<IEarnMetrics>('admin/metrics/earn'),
    enabled: true,
    select: res => formatEarnMetrics(res.data)
  })

  return (
    <MetricsCards
      isLoading={isLoading}
      isError={isError}
      data={data}
      cardsConfig={{ count: 6 }}
    />
  )
}

export default EarnMetrics
