import MetricsCards from '.'
import { useQuery } from '@tanstack/react-query'

import { IMetricCard, IReferMetrics } from '@/types/metrics/metrics.interface'

import api from '@/api'
import {
  formatNumber,
  msatsToSats,
  msatsToUSD,
  mstasToBtcAndFormat
} from '@/utils'

const formatReferMetrics = (data: IReferMetrics) => {
  return [
    {
      title: 'IBEX Balance',
      value: `${msatsToSats(data.ibex_balance_msats)} sats`
    },
    {
      title: 'Zero Hash Balance',
      value: `$${formatNumber(data.zh_balance_msats)}`
    },
    {
      title: 'Unclaimed Earnings',
      value: `$${msatsToUSD(data.unclaimed_msats, data.btc_price)}`,
      subtitle: `${mstasToBtcAndFormat(data.unclaimed_msats)} BTC`
    },
    {
      title: 'Total Claimed',
      value: `$${msatsToUSD(data.claimed_msats, data.btc_price)}`,
      subtitle: `${mstasToBtcAndFormat(data.claimed_msats)} BTC`
    }
  ] as IMetricCard[]
}

const ReferMetrics: React.FC = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['referMetrics'],
    queryFn: async () => await api.get<IReferMetrics>('/admin/refer/metrics'),
    enabled: true,
    select: res => formatReferMetrics(res.data)
  })

  return (
    <MetricsCards
      isLoading={isLoading}
      isError={isError}
      data={data}
      cardsConfig={{ count: 4 }}
    />
  )
}

export default ReferMetrics
