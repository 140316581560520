import { ColumnDef } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'

import MutationActionButton from '@/components/features/MutationActionButton'
import { Button } from '@/components/ui/Button'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IAccountGroup } from '@/types/account-groups/account-group.interface'

import formatId from '@/utils/formatId'

export const columns: ColumnDef<IAccountGroup>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return formatId(row.original.id)
    },
    enableSorting: false
  },
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Name' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50 text-white'>{row.original.name}</p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'accounts',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Accounts #' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50 text-white'>
          {row.original._count.accounts}
        </p>
      )
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Actions' />
    },
    cell: ({ row }) => {
      const navigate = useNavigate()
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <Button
            variant={'edit'}
            onClick={() =>
              navigate(`/users/groups/${row.original.id}`, {
                replace: true
              })
            }
          >
            Edit
          </Button>
          <MutationActionButton
            url={`/admin/account-groups/${row.original.id}`}
            description='Are you sure you want to delete this account group?'
            permissionSlug='account_groups_delete'
            successMessage='Account group deleted successfully'
            errorMessage='Failed to delete account group'
          />
        </div>
      )
    },
    enableSorting: false
  }
]
