import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field from '@/components/ui/Field'

import {
  IConfiguration,
  ICreateOrUpdateConfiguration
} from '@/types/configurations/configuration.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  initial?: IConfiguration
}

const CreateOrUpdateConfiguration: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateConfiguration>({
    mode: 'onChange',
    defaultValues: initial || {
      name: '',
      value: '',
      description: ''
    }
  })

  useEffect(() => {
    reset(
      initial || {
        name: '',
        value: '',
        description: ''
      }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-configuration' : 'add-configuration'],
    mutationFn: (data: ICreateOrUpdateConfiguration) =>
      api(
        initial ? `/admin/configuration/${initial.id}` : '/admin/configuration',
        {
          method: initial ? 'PATCH' : 'POST',
          data
        }
      ),
    onSuccess: () => {
      toast.success(
        `Configuration ${initial ? 'updated' : 'created'} successfully!`
      )
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          `An error occurred while ${initial ? 'updating' : 'creating'} configuration`
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateConfiguration> = async data =>
    mutate(data)

  return (
    <ProtectedComponent
      permissionSlug={
        initial ? 'configurations_update' : 'configurations_create'
      }
    >
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={initial ? 'edit' : 'create'}>
            {initial ? 'Edit' : 'Add Configuration'}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {initial ? 'Edit Configuration' : 'Add Configuration'}
            </DialogTitle>
            <DialogDescription>
              {initial
                ? 'Edit the configuration details'
                : 'Create a new configuration'}
            </DialogDescription>
          </DialogHeader>

          <form
            className='flex w-full flex-col gap-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              title='Name'
              placeholder='Name'
              className='!mt-0'
              disabled={!!initial}
              {...register('name', {
                required: 'Name is required',
                minLength: {
                  value: 3,
                  message: 'Name should be at least 3 characters'
                }
              })}
              error={errors.name?.message}
            />
            <Field
              title='Value'
              placeholder='Value'
              className='!mt-0'
              {...register('value', {
                required: 'Value is required'
              })}
              error={errors.value?.message}
            />
            <Field
              title='Description'
              placeholder='Description'
              className='!mt-0'
              {...register('description')}
              error={errors.description?.message}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default CreateOrUpdateConfiguration
