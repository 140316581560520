import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { AuthProvider } from '@/components/providers/AuthProvider'
import { ThemeProvider } from '@/components/providers/Provider'

import { Layout } from './components/layout'
import BankFundingsPage from './pages/Bank/BankFundings'
import BankTradesPage from './pages/Bank/BankTrades'
import BankConnectionsPage from './pages/Bank/Connections'
import BankEddRequestsPage from './pages/Bank/EDDRequests'
import BankVerificationsPage from './pages/Bank/Verifications'
import DeploymentsPage from './pages/Deployments'
import EarnTransactionsPage from './pages/Earn/Transactions'
import EarnUsersPage from './pages/Earn/Users'
import HomePage from './pages/Home'
import CategoriesPage from './pages/Learn/Categories'
import CreatorsPage from './pages/Learn/Creators'
import VideosPage from './pages/Learn/Videos'
import LoginPage from './pages/Login'
import MetricsPage from './pages/Metrics/Cards'
import MetricsChartsPage from './pages/Metrics/Charts'
import NotFoundPage from './pages/NotFound'
import NotificationsPage from './pages/Notifications'
import ConfigurationsPage from './pages/Organization/Configurations'
import IpAddressesPage from './pages/Organization/IpAddresses'
import RolesPage from './pages/Organization/Roles'
import TeamMembersPage from './pages/Organization/TeamMembers'
import ReferTransactionsPage from './pages/Refer/Transactions'
import ReferUsersPage from './pages/Refer/Users'
import ReferralsByAccountIdPage from './pages/Refer/Users/Id'
import SettingsPage from './pages/Settings'
import TransfersExchangePage from './pages/Transfers/Exchange'
import TransfersFlashPage from './pages/Transfers/Flash'
import TransfersStarterPage from './pages/Transfers/Starter'
import TransfersStashPage from './pages/Transfers/Stash'
import AccessCodesPage from './pages/Users/AccessCodes'
import AccountGroupsPage from './pages/Users/AccountGroups'
import AccountGroupByIdPage from './pages/Users/AccountGroups/ByID'
import UserDetailsPage from './pages/Users/Details'
import EmailWhitelistPage from './pages/Users/EmailWhitelist'
import UsernameBlacklistPage from './pages/Users/UsernameBlacklist'
import UsersPage from './pages/Users/Users'
import store, { persistor } from '@/store'

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthProvider />,
    children: [
      {
        path: '/login',
        element: <LoginPage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      },
      {
        element: <Layout />,
        children: [
          {
            path: '',
            element: <HomePage />
          },
          {
            path: 'settings',
            element: <SettingsPage />
          },
          {
            path: 'users',
            children: [
              {
                path: '',
                element: <UsersPage />
              },
              {
                path: 'username-blacklist',
                element: <UsernameBlacklistPage />
              },
              {
                path: 'email-whitelist',
                element: <EmailWhitelistPage />
              },
              {
                path: 'access-codes',
                element: <AccessCodesPage />
              },
              {
                path: 'groups',
                children: [
                  {
                    path: '',
                    element: <AccountGroupsPage />
                  },
                  {
                    path: ':id',
                    element: <AccountGroupByIdPage />
                  }
                ]
              },
              {
                path: ':userId',
                element: <UserDetailsPage />
              }
            ]
          },
          {
            path: 'metrics',
            children: [
              {
                path: '',
                element: <MetricsPage />
              },
              {
                path: 'charts',
                element: <MetricsChartsPage />
              }
            ]
          },
          {
            path: 'learn',
            children: [
              {
                path: '',
                element: <Navigate to='/learn/creators' replace />
              },
              {
                path: 'creators',
                element: <CreatorsPage />
              },
              {
                path: 'categories',
                element: <CategoriesPage />
              },
              {
                path: 'videos',
                element: <VideosPage />
              }
            ]
          },
          {
            path: 'earn',
            children: [
              {
                path: '',
                element: <Navigate to='/earn/users' replace />
              },
              {
                path: 'users',
                element: <EarnUsersPage />
              },
              {
                path: 'transactions',
                element: <EarnTransactionsPage />
              }
            ]
          },
          {
            path: 'refer',
            children: [
              {
                path: '',
                element: <Navigate to='/refer/users' replace />
              },
              {
                path: 'users',
                children: [
                  {
                    path: '',
                    element: <ReferUsersPage />
                  },
                  {
                    path: ':id',
                    element: <ReferralsByAccountIdPage />
                  }
                ]
              },
              {
                path: 'transactions',
                element: <ReferTransactionsPage />
              }
            ]
          },
          {
            path: 'notifications',
            element: <NotificationsPage />
          },
          {
            path: 'bank',
            children: [
              {
                path: '',
                element: <Navigate to='/bank/kyc' replace />
              },
              {
                path: 'kyc',
                element: <BankVerificationsPage />
              },
              {
                path: 'payment-methods',
                element: <BankConnectionsPage />
              },
              {
                path: 'trades',
                element: <BankTradesPage />
              },
              {
                path: 'fundings',
                element: <BankFundingsPage />
              },
              {
                path: 'edd-requests',
                element: <BankEddRequestsPage />
              }
            ]
          },
          {
            path: 'transfers',
            children: [
              {
                path: '',
                element: <Navigate to='/transfers/exchange' replace />
              },
              {
                path: 'exchange',
                element: <TransfersExchangePage />
              },
              {
                path: 'flash',
                element: <TransfersFlashPage />
              },
              {
                path: 'stash',
                element: <TransfersStashPage />
              },
              {
                path: 'starter',
                element: <TransfersStarterPage />
              }
            ]
          },
          {
            path: 'deployments',
            element: <DeploymentsPage />
          },
          {
            path: 'organization',
            children: [
              {
                path: '',
                element: <Navigate to='/organization/members' replace />
              },
              {
                path: 'members',
                element: <TeamMembersPage />
              },
              {
                path: 'roles',
                element: <RolesPage />
              },
              {
                path: 'configurations',
                element: <ConfigurationsPage />
              },
              {
                path: 'ip-addresses',
                element: <IpAddressesPage />
              }
            ]
          }
        ]
      }
    ]
  }
])

function App() {
  const [client] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: 1
        }
      }
    })
  )

  return (
    <QueryClientProvider client={client}>
      <ThemeProvider defaultTheme='dark' storageKey='vite-ui-theme'>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RouterProvider router={router} />
            <Toaster position='bottom-right' reverseOrder={false} />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
