import Page from '@/components/Page'
import EarnMetrics from '@/components/features/Metrics/Earn'
import { DataTable } from '@/components/ui/DataTable'

import { columns } from './columns'
import { EARN_TABS } from '@/constants/tabs'

const EarnUsersPage: React.FC = () => {
  return (
    <Page permissionSlug='earn_view' pageTitle='Earn' tabs={EARN_TABS}>
      <EarnMetrics />
      <DataTable
        columns={columns}
        url='/admin/users'
        totalText='rank'
        tableClassName='md:!max-h-[calc(100vh-550px)]'
        defaultSortBy='rank'
        defaultSortOrder='asc'
        method='POST'
      />
    </Page>
  )
}

export default EarnUsersPage
