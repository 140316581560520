import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import TextArea from '@/components/ui/TextArea'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface ICreateManyUsernameBlacklist {
  usernamesText: string
}

const usernameRegex = /^[a-zA-Z0-9_.]{3,}$/

const CreateMany: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateManyUsernameBlacklist>({
    mode: 'onChange',
    defaultValues: {
      usernamesText: ''
    }
  })

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: ['add-many-username-blacklist'],
    mutationFn: (data: { usernames: string[] }) =>
      api('/admin/username-blacklist/many', {
        method: 'POST',
        data
      }),
    onSuccess: () => {
      toast.success('Usernames added to blacklist successfully!')
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(errorMessage || 'Failed to add usernames to blacklist')
    }
  })

  const onSubmit: SubmitHandler<ICreateManyUsernameBlacklist> = data => {
    const usernamesSet = new Set<string>()
    const invalidUsernames: string[] = []

    const lines = data.usernamesText.split('\n')
    lines.forEach(line => {
      if (!line.trim()) {
        return
      }

      const parts = line.split('/')
      parts.forEach(part => {
        const username = part.trim().replace(/^@/, '')
        if (username.length >= 2 && usernameRegex.test(username)) {
          usernamesSet.add(username)
        } else {
          invalidUsernames.push(username)
        }
      })
    })

    const usernames = Array.from(usernamesSet)

    if (usernames.length === 0) {
      toast.error('No valid usernames found')
      return
    }

    if (invalidUsernames.length > 0) {
      toast.error(
        `Invalid usernames detected: ${invalidUsernames.join(',\n')}. They are be skipped.`
      ),
        {
          duration: 100000
        }
    }

    mutate({ usernames })
  }

  return (
    <ProtectedComponent permissionSlug={'username_blacklist_update'}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={'create'}>Add Usernames</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add some Usernames to Blacklist</DialogTitle>
            <DialogDescription>
              Add multiple usernames to the blacklist
            </DialogDescription>
          </DialogHeader>
          <form
            className='mt-4 flex flex-col gap-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextArea
              placeholder='Paste usernames here (e.g., @user1 / @user2)'
              rows={10}
              {...register('usernamesText', {
                required: 'Usernames text is required'
              })}
              error={errors.usernamesText?.message}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default CreateMany
