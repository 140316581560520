import { useQuery } from '@tanstack/react-query'
import { ChartData } from 'chart.js'
import 'chartjs-adapter-date-fns'
import React, { useState } from 'react'

import { IStarterMetricsData } from '@/types/metrics-charts'

import Chart from './Chart'
import api from '@/api'

const StarterMetricsChart: React.FC = () => {
  const [range, setRange] = useState<'day' | 'week' | 'month' | 'all'>('day')

  const { data, isLoading, isError } = useQuery({
    queryKey: ['starterMetrics', range],
    queryFn: async () =>
      await api.get<IStarterMetricsData>(
        `/admin/metrics/charts/starter?range=${range}`
      ),
    enabled: true,
    select: res => {
      const rawData = res.data.data
      const labels = rawData.map(d => new Date(d.timestamp))

      const totalBalanceMsats = rawData.map(d => d.total_balance_msats)
      const totalBalanceUsd = rawData.map(d => d.total_balance_usd)
      const volumeReceivedMsats = rawData.map(d => d.volume_received_msats)
      const volumeReceivedUsd = rawData.map(d => d.volume_received_usd)
      const volumeSentMsats = rawData.map(d => d.volume_sent_msats)
      const volumeSentUsd = rawData.map(d => d.volume_sent_usd)
      const paymentsMade1h = rawData.map(d => d.payments_made_1h)
      const paymentsMade24h = rawData.map(d => d.payments_made_24h)
      const paymentsMade30d = rawData.map(d => d.payments_made_30d)
      const paymentsMadeAll = rawData.map(d => d.payments_made_all)

      const chartData: ChartData<'line'> = {
        labels,
        datasets: [
          {
            label: 'Total Balance (msats)',
            data: totalBalanceMsats,
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Total Balance (USD)',
            data: totalBalanceUsd,
            fill: false,
            borderColor: 'rgba(255,99,132,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5
          },
          {
            label: 'Volume Received (msats)',
            data: volumeReceivedMsats,
            fill: false,
            borderColor: 'rgba(54,162,235,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Volume Received (USD)',
            data: volumeReceivedUsd,
            fill: false,
            borderColor: 'rgba(255,159,64,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Volume Sent (msats)',
            data: volumeSentMsats,
            fill: false,
            borderColor: 'rgba(153,102,255,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Volume Sent (USD)',
            data: volumeSentUsd,
            fill: false,
            borderColor: 'rgba(255,205,86,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Payments Made (1h)',
            data: paymentsMade1h,
            fill: false,
            borderColor: 'rgba(75,192,192,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Payments Made (24h)',
            data: paymentsMade24h,
            fill: false,
            borderColor: 'rgba(255,99,132,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Payments Made (30d)',
            data: paymentsMade30d,
            fill: false,
            borderColor: 'rgba(54,162,235,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Payments Made (All)',
            data: paymentsMadeAll,
            fill: false,
            borderColor: 'rgba(153,102,255,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          }
        ]
      }

      return chartData
    }
  })

  return (
    <Chart
      title='Starter Metrics'
      setSelectedTab={setRange}
      selectedTab={range}
      isLoading={isLoading}
      isError={isError}
      data={data}
    />
  )
}

export default StarterMetricsChart
