import Page from '@/components/Page'
import { DataTable } from '@/components/ui/DataTable'

import CreateOrUpdateRole from './CreateOrUpdateRole'
import { ORGANIZATION_TABS } from '@/constants/tabs'
import { columns } from '@/pages/Organization/Roles/columns'

const RolesPage: React.FC = () => {
  return (
    <Page
      permissionSlug='organization_view'
      pageTitle='Roles'
      tabs={ORGANIZATION_TABS}
      header={<CreateOrUpdateRole />}
    >
      <DataTable
        url='admin/roles'
        totalText='roles'
        columns={columns}
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}

export default RolesPage
