import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import MutationActionButton from '@/components/features/MutationActionButton'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'
import Icon from '@/components/ui/Icon'

import { ICategory } from '@/types/learn/category.interface'

import formatId from '@/utils/formatId'

import CreateOrUpdateCategory from '@/pages/Learn/Categories/CreateOrUpdateCategory'

export const columns: ColumnDef<ICategory>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Category Name' />
    },
    cell: ({ row }) => {
      if (row.original.name) {
        return <p className='text-foreground/50'>{row.original.name}</p>
      } else {
        return <p className='text-foreground/50'>N/A</p>
      }
    }
  },
  {
    accessorKey: 'number_of_videos',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='# of videos' />
    },
    cell: ({ row }) => {
      return row.original.number_of_videos
    }
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Visible' />
    },
    cell: ({ row }) => {
      return row.original.status ? (
        <Icon icon='Approved' className='size-6' />
      ) : (
        <Icon icon='Rejected' className='size-6' />
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <CreateOrUpdateCategory
            initial={row.original}
            url={`admin/learn/categories/${row.original.id}`}
          />
          <MutationActionButton
            url={`/admin/learn/categories/${row.original.id}`}
            description='Are you sure you want to delete this category?'
            permissionSlug='learn_delete'
            successMessage='Category deleted successfully'
            errorMessage='Failed to delete category'
          />
        </div>
      )
    },
    enableSorting: false
  }
]
