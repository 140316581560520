import { useState } from 'react'

import Page from '@/components/Page'
import UsersMetrics from '@/components/features/Metrics/Users'
import { DataTable } from '@/components/ui/DataTable'
import Icon from '@/components/ui/Icon'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/Select'

import { KycStatus } from '@/types/enums'

import { columns } from './columns'
import { USERS_TABS } from '@/constants/tabs'

const UsersPage: React.FC = () => {
  const [kycStatus, setKycStatus] = useState<KycStatus | 'all' | undefined>(
    undefined
  )

  return (
    <Page pageTitle='Users' tabs={USERS_TABS}>
      <UsersMetrics />
      <DataTable
        columns={columns}
        url='/admin/users'
        totalText='users'
        tableClassName='md:!max-h-[calc(100vh-550px)]'
        body={{ status: kycStatus }}
        method='POST'
      >
        <Select
          value={kycStatus}
          onValueChange={val => {
            if (val === undefined) {
              setKycStatus(undefined)
              return
            }
            setKycStatus(val as KycStatus | undefined)
          }}
        >
          <SelectTrigger className='mt-0 w-[180px] rounded-3xl'>
            <SelectValue placeholder='Select KYC Status' />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={'all'}>All</SelectItem>
            <SelectItem value={KycStatus.FAILED}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Rejected' className='size-4' />
                Failed
              </div>
            </SelectItem>
            <SelectItem value={KycStatus.STARTED}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='InProgress' className='size-4' />
                Started
              </div>
            </SelectItem>
            <SelectItem value={KycStatus.VERIFIED}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Approved' className='size-4' />
                Verified
              </div>
            </SelectItem>
            <SelectItem value={KycStatus.VERIFIED_NO_SSN}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Approved' className='size-4' />
                Verified No SSN
              </div>
            </SelectItem>
            <SelectItem value={KycStatus.UNVERIFIED}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Unverified' className='size-4' />
                Unverified
              </div>
            </SelectItem>
          </SelectContent>
        </Select>
      </DataTable>
    </Page>
  )
}

export default UsersPage
