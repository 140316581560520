import { IUser } from '@/types/account/user.interface'

export enum BankVerificationType {
  IDENTITY = 'IDENTITY',
  EXTERNAL_BANK_ACCOUNT = 'EXTERNAL_BANK_ACCOUNT'
}

export enum BankVerificationMethod {
  ID_AND_SELFIE = 'ID_AND_SELFIE',
  TAX_ID_AND_SELFIE = 'TAX_ID_AND_SELFIE',
  ATTESTED = 'ATTESTED',
  PLAID_IDENTITY_MATCH = 'PLAID_IDENTITY_MATCH',
  DOCUMENT_SUBMISSION = 'DOCUMENT_SUBMISSION'
}

export enum BankVerificationStatus {
  STORING = 'STORING',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  WAITING = 'WAITING',
  EXPIRED = 'EXPIRED',
  COMPLETED = 'COMPLETED'
}

export enum BankVerificationResult {
  PASSED = 'PASSED',
  FAILED = 'FAILED'
}

export enum PersonaStatus {
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  REVIEWING = 'REVIEWING',
  PROCESSING = 'PROCESSING',
  EXPIRED = 'EXPIRED',
  COMPLETED = 'COMPLETED',
  UNKNOWN = 'UNKNOWN'
}

export interface IBankVerification {
  id: string
  created_at: Date
  updated_at: Date

  provider_id: string
  type: BankVerificationType
  method: BankVerificationMethod
  status: BankVerificationStatus
  result?: BankVerificationResult
  failure_codes: string[]

  persona_inquiry_id?: string
  persona_status?: PersonaStatus

  account: IUser
  account_id: string
}
