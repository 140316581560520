import i18nCountries from 'i18n-iso-countries'
import enLocales from 'i18n-iso-countries/langs/en.json'

i18nCountries.registerLocale(enLocales)

interface Props {
  isoCode: string
}

const GetCountryNameByIsoCode: React.FC<Props> = ({ isoCode }) => {
  const countryName = i18nCountries.getName(isoCode, 'en')
  return (
    <p className={countryName ? 'text-foreground' : 'text-foreground/50'}>
      {countryName || 'Unknown'}
    </p>
  )
}

export default GetCountryNameByIsoCode
