import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import FailureCodeComponent from '@/components/features/FailureCode'
import GetCountryNameByIsoCode from '@/components/features/GetCountryNameFromIsoCode'
import MutationActionButton from '@/components/features/MutationActionButton'
import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import {
  BankVerificationResult,
  IBankVerification
} from '@/types/bank-verification/bank-verification.interface'
import { KycStatus } from '@/types/enums'

import formatId from '@/utils/formatId'

import { cn } from '@/utils'

export const columns: ColumnDef<IBankVerification>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'provider_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Provider ID' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.provider_id}`)
            toast.success('Provider ID Copied to clipboard')
          }}
        >
          {formatId(row.original.provider_id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'user_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='User ID' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.account.id}`)
            toast.success('User ID Copied to clipboard')
          }}
        >
          {formatId(row.original.account.id)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'account',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account' />
    },
    cell: ({ row }) => {
      return <UserDetailsModal fromOtherPage user={row.original.account} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'account.email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.account.email}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'country',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Country' />
    },
    cell: ({ row }) => {
      const account = row.original.account

      if (
        account.ip_addresses &&
        account.ip_addresses[0] &&
        account.ip_addresses[0].country
      ) {
        return (
          <GetCountryNameByIsoCode isoCode={account.ip_addresses[0].country} />
        )
      }

      return <p className='text-foreground/50'>Uknown</p>
    }
  },
  {
    accessorKey: 'legal_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Legal Name' />
    },
    cell: ({ row }) => {
      return (
        <p
          className={cn('text-foreground/50', {
            'text-foreground':
              row.original.account.kyc_status === KycStatus.VERIFIED ||
              row.original.account.kyc_status === KycStatus.VERIFIED_NO_SSN
          })}
        >
          {row.original.account.first_name} {row.original.account.last_name}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'method',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Method' />
    },
    cell: ({ row }) => {
      const method = row.original.method

      return (
        <button
          className='text-foreground/50'
          onClick={() => {
            if (!method) return
            navigator.clipboard.writeText(method)
            toast.success('Method Copied to clipboard')
          }}
        >
          {method
            .replace(/_/g, ' ')
            .toLowerCase()
            .replace(/\b\w/g, l => l.toUpperCase())}
        </button>
      )
    }
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Status' />
    },
    cell: ({ row }) => {
      const status = row.original.status

      return (
        <button
          className='text-foreground/50'
          onClick={() => {
            if (!status) return
            navigator.clipboard.writeText(status)
            toast.success('Status Copied to clipboard')
          }}
        >
          {status
            .replace(/_/g, ' ')
            .toLowerCase()
            .replace(/\b\w/g, l => l.toUpperCase())}
        </button>
      )
    }
  },
  {
    accessorKey: 'result',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Result' />
    },
    cell: ({ row }) => {
      const result = row.original.result

      return (
        <button
          className='text-foreground/50'
          onClick={() => {
            if (!result) return
            navigator.clipboard.writeText(result)
            toast.success('Result Copied to clipboard')
          }}
        >
          {result
            ? result
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, l => l.toUpperCase())
            : 'N/A'}
        </button>
      )
    }
  },
  {
    accessorKey: 'failure_codes',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Failure Codes' />
    },
    cell: ({ row }) => {
      const codes = row.original.failure_codes

      return (
        <div className='text-foreground/50'>
          {codes.length > 0 ? (
            <>
              {codes.map((code, index) => (
                <FailureCodeComponent key={index} failureCode={code} />
              ))}
            </>
          ) : (
            'N/A'
          )}
        </div>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {new Date(row.original.created_at).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      if (
        row.original.result === BankVerificationResult.FAILED &&
        row.original.account.is_kyc_blocked
      ) {
        return (
          <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
            <MutationActionButton
              url={`/admin/bank/verifications/${row.original.id}/unblock`}
              method='PUT'
              successMessage='User unblocked'
              errorMessage='Failed to unblock user'
              description='Unblock user to allow them to try again'
              title='Unblock'
              permissionSlug='kyc_verifications_unblock'
            />
          </div>
        )
      }
    },
    enableSorting: false
  }
]
