import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import Page from '@/components/Page'
import BackButton from '@/components/ui/ButtonBack'
import { DataTable } from '@/components/ui/DataTable'

import { IAccountGroup } from '@/types/account-groups/account-group.interface'

import { AddToGroup } from './AddToGroup'
import { columns } from './columns'
import api from '@/api'

const AccountGroupByIdPage: React.FC = () => {
  const { id } = useParams()

  const { data, isLoading } = useQuery({
    queryKey: [`/admin/account-groups/${id}`],
    queryFn: async () => {
      const resp = await api.get<IAccountGroup>(`/admin/account-groups/${id}`)

      return resp.data
    }
  })

  return (
    <Page
      permissionSlug='account_groups_view'
      pageTitle='Account Group'
      title={isLoading ? 'Loading...' : `${data?.name}`}
      header={
        <div className='flex w-full flex-wrap justify-between gap-2'>
          <BackButton href='/users/groups' />
          <AddToGroup groupId={id || ''} />
        </div>
      }
    >
      <DataTable
        url={`/admin/account-groups/${id}/accounts`}
        columns={columns}
        totalText='accounts'
      />
    </Page>
  )
}

export default AccountGroupByIdPage
