import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

import ProtectedComponent from '@/components/Protected'
import AccountSelect from '@/components/features/AccountSelect'
import { Button } from '@/components/ui/Button'
import {
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger
} from '@/components/ui/Sheet'

import { IBankEddRequest } from '@/types/bank-edd-request/bank-request.interface'

interface Props {
  initial: IBankEddRequest
}

const whitelistedFields: string[] = [
  'first_name',
  'last_name',
  'address_one',
  'address_two',
  'city',
  'zip',
  'id_number_type',
  'phone_number',
  'employment_status',
  'industry',
  'source_of_funds',
  'onboarded_location',
  'jurisdiction_code',
  'citizenship_code'
]

const fieldLabels: Record<string, string> = {
  first_name: 'First Name',
  last_name: 'Last Name',
  address_one: 'Address One',
  address_two: 'Address Two',
  city: 'City',
  zip: 'Zip',
  id_number_type: 'ID Number Type',
  phone_number: 'Phone Number',
  employment_status: 'Employment Status',
  industry: 'Industry',
  source_of_funds: 'Source Of Funds',
  onboarded_location: 'Onboarded Location',
  jurisdiction_code: 'Jurisdiction Code',
  citizenship_code: 'Citizenship Code'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderField = (key: string, value: any, visibleKey: string) => {
  if (!value[visibleKey]) {
    return null
  }
  return (
    <div
      className='flex flex-row justify-between gap-2 text-base font-medium text-foreground/50'
      key={key}
    >
      {fieldLabels[key] || key}: <p>{value[key] || 'N/A'}</p>
    </div>
  )
}

const ViewEDDRequest: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { handleSubmit } = useForm()

  const { isPending, mutate } = useMutation({
    mutationKey: ['pass-edd-request']
    // mutationFn: (data: IBankEddRequest) => {
    //   // console.log(data)
    //   return Promise.resolve()
    // }
  })

  const onSubmit = async () => mutate()

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger>
        <Button variant={'edit'}>View</Button>
      </SheetTrigger>
      <SheetContent className='h-screen w-full border-solid border-gray500/50 bg-gray800 sm:max-w-lg '>
        <form
          className='flex h-[calc(100svh-100px)] w-full flex-col gap-2 overflow-y-auto p-4 md:gap-4 md:p-6'
          onSubmit={handleSubmit(onSubmit)}
        >
          <SheetTitle>EDD Request</SheetTitle>

          <label className='flex flex-col gap-2'>
            <p className='font-semibold'>Account</p>
            <AccountSelect
              initialAccount={initial?.account}
              accountId={initial.account.id}
              setAccountId={() => {}}
            />
          </label>

          <p className='font-semibold'>Details</p>
          <div className='flex flex-col gap-4'>
            {Object.entries(initial)
              .filter(([key]) => whitelistedFields.includes(key))
              .map(([key]) => renderField(key, initial, `${key}_visible`))}
          </div>

          <ProtectedComponent permissionSlug='edd_requests_approve'>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Approve'}
            </Button>
          </ProtectedComponent>
        </form>
      </SheetContent>
    </Sheet>
  )
}

export default ViewEDDRequest
