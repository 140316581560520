import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field from '@/components/ui/Field'

import {
  ICreateOrUpdateEmailWhitelist,
  IEmailWhitelist
} from '@/types/email-whitelist.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  initial?: IEmailWhitelist
}

const CreateOrUpdate: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateEmailWhitelist>({
    mode: 'onChange',
    defaultValues: initial || {
      email: ''
    }
  })

  useEffect(() => {
    reset(
      initial || {
        email: ''
      }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-email-whitelist' : 'add-email-whitelist'],
    mutationFn: (data: ICreateOrUpdateEmailWhitelist) =>
      api(
        initial
          ? `/admin/email-whitelist/${initial.id}`
          : '/admin/email-whitelist',
        {
          method: initial ? 'PATCH' : 'POST',
          data
        }
      ),
    onSuccess: () => {
      toast.success(
        `Email Whitelist item ${initial ? 'updated' : 'created'} successfully!`
      )
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          `Failed to ${initial ? 'update' : 'create'} Email Whitelist item`
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateEmailWhitelist> = async data =>
    mutate(data)

  return (
    <ProtectedComponent permissionSlug={'email_whitelist_update'}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={initial ? 'edit' : 'create'}>
            {initial ? 'Edit' : 'Add Email'}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {initial ? 'Edit Email' : 'Add Email to Whitelist'}
            </DialogTitle>
            <DialogDescription>
              {initial
                ? 'Edit the email details'
                : 'Add a new email to the whitelist'}
            </DialogDescription>
          </DialogHeader>
          <form
            className='mt-4 flex flex-col gap-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              placeholder='email'
              {...register('email', {
                required: 'Email is required',
                minLength: {
                  value: 3,
                  message: 'Email must be at least 3 characters'
                },
                maxLength: {
                  value: 255,
                  message: 'Email must be at most 255 characters'
                },
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Invalid email format'
                }
              })}
              error={errors.email?.message}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default CreateOrUpdate
