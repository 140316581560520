import { useState } from 'react'

import Page from '@/components/Page'
import { DataTable } from '@/components/ui/DataTable'
import Icon from '@/components/ui/Icon'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/Select'

import { BankVerificationStatus } from '@/types/bank-verification/bank-verification.interface'

import { columns } from './columns'
import { BANK_TABS } from '@/constants/tabs'

const BankVerificationsPage: React.FC = () => {
  const [status, setStatus] = useState<
    BankVerificationStatus | 'all' | undefined
  >(undefined)

  return (
    <Page
      permissionSlug='kyc_verifications_view'
      pageTitle='KYC verifications'
      tabs={BANK_TABS}
    >
      <DataTable
        url='admin/bank/verifications'
        columns={columns}
        body={{ status }}
        method='POST'
      >
        <Select
          value={status}
          onValueChange={val => {
            if (val === undefined) {
              setStatus(undefined)
              return
            }
            setStatus(val as BankVerificationStatus | undefined)
          }}
        >
          <SelectTrigger className='mt-0 w-[180px] rounded-3xl'>
            <SelectValue placeholder='Select KYC Status' />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={'all'}>All</SelectItem>
            <SelectItem value={BankVerificationStatus.FAILED}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Rejected' className='size-4' />
                Failed
              </div>
            </SelectItem>
            <SelectItem value={BankVerificationStatus.EXPIRED}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Unverified' className='size-4' />
                Expired
              </div>
            </SelectItem>
            <SelectItem value={BankVerificationStatus.CANCELLED}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Unverified' className='size-4' />
                Cancelled
              </div>
            </SelectItem>
            <SelectItem value={BankVerificationStatus.STORING}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='InProgress' className='size-4' />
                Storing
              </div>
            </SelectItem>
            <SelectItem value={BankVerificationStatus.WAITING}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='InProgress' className='size-4' />
                Waiting
              </div>
            </SelectItem>
            <SelectItem value={BankVerificationStatus.COMPLETED}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Approved' className='size-4' />
                Completed
              </div>
            </SelectItem>
          </SelectContent>
        </Select>
      </DataTable>
    </Page>
  )
}

export default BankVerificationsPage
