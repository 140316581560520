import Page from '@/components/Page'
import { DataTable } from '@/components/ui/DataTable'

import { columns } from './columns'
import { TRANSFERS_TABS } from '@/constants/tabs'

const TransfersStashPage: React.FC = () => {
  return (
    <Page
      permissionSlug='transfers_view'
      pageTitle='Stash Transfers'
      tabs={TRANSFERS_TABS}
    >
      <DataTable url='admin/transfer/stash' columns={columns} />
    </Page>
  )
}

export default TransfersStashPage
