import { ReactNode } from 'react'

import ProtectedComponent from '@/components/Protected'
import AnimatedLoader from '@/components/ui/Loader'

import { cn } from '@/utils'

export interface SectionOption {
  label: string | ReactNode
  rightContent?: string | ReactNode
  permissionSlug?: string
  selfStart?: boolean
}
interface SectionProps {
  title?: string
  options: SectionOption[] | null
  avatar?: string
  loading?: boolean
}

export const Section = ({ title, options, avatar, loading }: SectionProps) => {
  if (!options) {
    return null
  }

  return (
    <div className='flex w-full flex-col'>
      <div className='flex flex-row items-center justify-between'>
        {title && (
          <p className='text-[20px] font-bold tracking-normal text-white'>
            {title}
          </p>
        )}
        {avatar && (
          <img
            src={avatar}
            alt=''
            className='h-[100px] w-[100px] rounded-full bg-gray900'
          />
        )}
      </div>
      {loading ? (
        <AnimatedLoader />
      ) : (
        <div
          className={cn('my-5 flex flex-col', {
            'mt-0': !title && !avatar
          })}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className={cn(
                'flex flex-row items-center justify-between gap-[8px]',
                index !== options.length - 1 && 'mb-[15px]'
              )}
            >
              <p
                className={cn('text-[14px] font-semibold text-white/50', {
                  'self-start': option.selfStart
                })}
              >
                {option.label}
              </p>
              <ProtectedComponent permissionSlug={option.permissionSlug}>
                {typeof option.rightContent === 'string' ? (
                  <p
                    title={option.rightContent}
                    className='long-text text-[14px] font-semibold text-white'
                  >
                    {option.rightContent}
                  </p>
                ) : (
                  option.rightContent
                )}
              </ProtectedComponent>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
