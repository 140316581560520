import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/Button'
import Field from '@/components/ui/Field'

import { useActions } from '@/hooks/use-actions'

import { ILogin, ILoginResponse } from '@/types/auth/login.interface'
import { IResponseWithError } from '@/types/error.types'

import api from '@/api'
import { cn } from '@/utils'

const LoginPage: React.FC = () => {
  const navigate = useNavigate()
  const { login } = useActions()

  const [isFinalizing, setIsFinalizing] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ILogin>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      otp: ''
    }
  })

  const { mutate, isPending } = useMutation({
    mutationKey: ['login'],
    mutationFn: async (data: ILogin) => {
      if (isFinalizing) {
        const resp = await api.post<ILoginResponse>(
          '/admin/auth/login/finalize',
          {
            email: data.email,
            otp: data.otp
          }
        )
        return resp.data
      }
      const resp = await api.post<ILoginResponse>('/admin/auth/login', {
        email: data.email,
        password: data.password
      })
      return resp.data
    },
    onError: (resp: IResponseWithError) => {
      const message = resp?.response?.data.error?.message || 'An error occurred'
      toast.error(message)
    },
    onSuccess: (resp: ILoginResponse) => {
      if (!isFinalizing) {
        setIsFinalizing(true)
        toast.success('OTP sent to your email')
        return
      }

      login(resp)
      navigate('/users', { replace: true })
    }
  })

  const onSubmit: SubmitHandler<ILogin> = async data => mutate(data)

  return (
    <div className='flex h-screen w-screen items-center justify-center bg-gray900 px-4'>
      <div
        className={cn(
          'relative flex w-screen max-w-[440px] flex-col bg-gray900 p-[30px]',
          'rounded-[15px] border border-white/15'
        )}
      >
        <span className='text-xl font-bold leading-[29px] text-white md:text-2xl'>
          Log In
        </span>
        {!isFinalizing && (
          <form
            className='mt-4 flex w-full flex-col items-start justify-center gap-3'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              title='Email'
              placeholder='Email'
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Invalid email address'
                }
              })}
              className='mt-0'
              error={errors.email?.message}
            />
            <Field
              title='Password'
              placeholder='Password'
              type='password'
              {...register('password', {
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters'
                },
                pattern: {
                  message:
                    'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character',
                  value:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/
                }
              })}
              className='mt-0'
              error={errors.password?.message}
            />
            <Button
              disabled={isPending}
              size={'lg'}
              className='mt-2 w-full'
              rounded={'md'}
            >
              {isPending ? 'Loading...' : 'Continue'}
            </Button>
          </form>
        )}
        {isFinalizing && (
          <>
            <span className='text-sm text-white/50'>
              Enter the OTP you received in your e-mail
            </span>
            <form
              className='mt-4 flex w-full flex-col items-start justify-center gap-3'
              onSubmit={handleSubmit(onSubmit)}
            >
              <Field
                title='OTP'
                placeholder='OTP'
                {...register('otp', {
                  required: 'OTP is required',
                  minLength: {
                    value: 6,
                    message: 'OTP must be 6 characters'
                  },
                  pattern: {
                    value: /^[0-9]{6}$/,
                    message: 'OTP must contain only numbers'
                  }
                })}
                className='mt-0'
                error={errors.otp?.message}
              />
              <Button
                disabled={isPending}
                size={'lg'}
                className='mt-2 w-full'
                rounded={'md'}
              >
                {isPending ? 'Loading...' : 'Log In'}
              </Button>
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default LoginPage
