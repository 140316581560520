export enum DeploymentType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  APK = 'APK'
}

export interface IDeployment {
  id: string
  type: DeploymentType
  version: string
  description?: string
  href?: string
  files: IAPKFile[]
  created_at: Date
  updated_at: Date
}

export interface IAPKFile {
  id: string
  key: string
  href: string
  deployment: IDeployment
  deploymentId: string
  created_at: Date
  updated_at: Date
}

export interface ICreateOrUpdateDeployment {
  type: DeploymentType
  href: string
  version: string
  description?: string
}

export interface ICreateOrUpdateAPKFile {
  version: string
  description?: string
  file: File | null
}
