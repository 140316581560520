import { keepPreviousData, useQuery } from '@tanstack/react-query'
import {
  ColumnDef,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { IUser } from '@/types/account/user.interface'
import { ResultWithPagination } from '@/types/pagination.interface'

import { Input } from '../Input'

import { DataTablePagination } from './Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from './Table'
import { DataTableViewOptions } from './ViewOptions'
import api from '@/api'
import { cn } from '@/utils'

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

interface Props<T> {
  url: string
  data?: T[]
  title?: string
  body?: unknown
  columns: ColumnDef<T>[]
  totalText?: string
  className?: string
  tableClassName?: string
  defaultSortBy?: string
  defaultSortOrder?: 'asc' | 'desc'
  children?: React.ReactNode
  method?: 'GET' | 'POST'
}

export function DataTable<T>({
  columns,
  url,
  title,
  data: propData,
  body,
  totalText,
  className,
  tableClassName,
  defaultSortBy,
  defaultSortOrder,
  children,
  method = 'GET'
}: Props<T>) {
  const [searchParams, setSearchParams] = useSearchParams()

  const getPaginationState = (): PaginationState => ({
    pageIndex: Number(searchParams.get('pageIndex')) || 0,
    pageSize: Number(searchParams.get('pageSize')) || 50
  })

  const getSortingState = (): SortingState => {
    const sortBy = searchParams.get('sort_by') || defaultSortBy
    const sortOrder = searchParams.get('sort_order') || defaultSortOrder
    if (sortBy && sortOrder) {
      return [{ id: sortBy, desc: sortOrder === 'desc' }]
    }
    return []
  }

  const getSearchState = (): string => searchParams.get('search') || ''

  const [pagination, setPagination] =
    useState<PaginationState>(getPaginationState)
  const [sorting, setSorting] = useState<SortingState>(getSortingState)
  const [search, setSearch] = useState<string>(getSearchState)

  const debouncedSearch = useDebounce<string>(search, 500)
  const abortControllerRef = useRef<AbortController | null>(null)

  useEffect(() => {
    if (!propData) {
      setSearchParams({
        pageIndex: pagination.pageIndex.toString(),
        pageSize: pagination.pageSize.toString(),
        sort_by: sorting.length ? sorting[0].id : '',
        sort_order: sorting.length ? (sorting[0].desc ? 'desc' : 'asc') : '',
        search: debouncedSearch
      })
    }
  }, [pagination, sorting, debouncedSearch, setSearchParams, propData])

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'data-table',
      url,
      method,
      pagination,
      sorting,
      debouncedSearch,
      body
    ],
    queryFn: async () => {
      if (propData) {
        const total = propData.length
        const offset = pagination.pageIndex * pagination.pageSize
        const limit = pagination.pageSize

        return {
          result: propData.slice(offset, offset + limit),
          pagination: {
            total,
            offset,
            limit
          }
        }
      }

      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }

      abortControllerRef.current = new AbortController()

      const resp = await api<ResultWithPagination<T>>(url, {
        method: method,
        params: {
          offset: pagination.pageIndex * pagination.pageSize,
          limit: pagination.pageSize,
          sort_by: sorting.length ? sorting[0].id : undefined,
          sort_order: sorting.length
            ? sorting[0].desc
              ? 'desc'
              : 'asc'
            : undefined,
          search: debouncedSearch
        },
        data: body,
        signal: abortControllerRef.current.signal
      })

      abortControllerRef.current = null

      if (
        resp.data.pagination.total <
        pagination.pageIndex * pagination.pageSize
      ) {
        setPagination({
          ...pagination,
          pageIndex: Math.max(
            0,
            Math.ceil(resp.data.pagination.total / pagination.pageSize) - 1
          )
        })
      }

      return resp.data
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData
  })

  const table = useReactTable({
    data: data?.result || [],
    columns,
    rowCount: data?.pagination?.total || 0,
    state: {
      pagination,
      sorting
    },
    initialState: {
      columnVisibility: {
        id: false,
        legal_name: false,
        provider_id: false,
        user_id: false
      }
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel()
  })

  const returnRankUpdatedAt = () => {
    if (!data || !data.result || !data.result.length) return null

    const firstUser = data?.result?.[0] as IUser

    return (
      <div className='text-xs font-medium text-foreground'>
        {data?.pagination?.total} Users Total | Ranks updated at{' '}
        <br className='lg:hidden' />
        {new Date(firstUser.rank_updated_at).toLocaleString('en-US', {
          month: 'short',
          day: '2-digit',
          year: undefined,
          hour: '2-digit',
          minute: '2-digit',
          second: undefined
        })}
      </div>
    )
  }

  return (
    <div className={cn('rounded-5xl border bg-gray800', className)}>
      <div className='flex items-center justify-between px-4 py-2 md:py-4'>
        {title ? (
          <h2 className='text-lg font-bold'>{title}</h2>
        ) : (
          <div className='flex w-full flex-wrap gap-2'>
            <Input
              placeholder={'Search'}
              value={search}
              onChange={e => {
                setSearch(e.target.value)
                setPagination({ ...pagination, pageIndex: 0 })
              }}
              className='max-w-[300px] !rounded-[32px]'
            />
            {children}
          </div>
        )}
        <DataTableViewOptions table={table} />
      </div>
      <Table
        className={cn(
          'max-h-[calc(100vh-300px)] md:max-h-[calc(100vh-450px)]',
          {
            '!overflow-x-hidden': !data?.result?.length
          },
          tableClassName
        )}
      >
        <TableHeader>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length} className='h-24'>
                <p className='!max-w-[75vw] text-center text-lg font-bold'>
                  Loading...
                </p>
              </TableCell>
            </TableRow>
          ) : isError ? (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className='text-center font-bold'
              >
                Error loading data
              </TableCell>
            </TableRow>
          ) : table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map(cell => (
                  <TableCell
                    key={cell.id}
                    className={cn({
                      '!m-0 !ml-auto !w-[180px] place-self-end self-end !p-0':
                        !!cell.id.includes('actions')
                    })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className='h-24'>
                <p className='!max-w-[75vw] text-center text-lg font-bold'>
                  No data found
                </p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className='flex w-full items-center justify-between gap-4 p-2 md:p-4'>
        {totalText === 'rank' ? (
          <>{returnRankUpdatedAt()}</>
        ) : (
          <div className='text-sm font-medium text-foreground'>
            {data?.pagination?.total} {totalText || 'Total'}
          </div>
        )}
        {!propData && <DataTablePagination table={table} />}
      </div>
    </div>
  )
}
