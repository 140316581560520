import { LoaderIcon } from 'lucide-react'

import { cn } from '@/utils'

interface Props {
  className?: string
}

const AnimatedLoader: React.FC<Props> = ({ className }) => {
  return (
    <LoaderIcon className={cn('z-20 my-auto size-8 animate-spin', className)} />
  )
}

export default AnimatedLoader
