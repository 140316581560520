import { ChartData } from 'chart.js'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { LoaderIcon } from 'lucide-react'
import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'

import Menu from '@/components/ui/Menu'

import { getChartOptions } from './get-chart-options'

interface Props {
  title: string
  setSelectedTab: (selectedRange: 'day' | 'week' | 'month' | 'all') => void
  selectedTab: 'day' | 'week' | 'month' | 'all'
  isLoading: boolean
  isError: boolean
  data?: ChartData<'line'>
}

ChartJS.register(
  TimeScale,
  LinearScale,
  CategoryScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
)

const Chart: React.FC<Props> = ({
  title,
  setSelectedTab,
  selectedTab,
  isLoading,
  isError,
  data
}) => {
  const options = useMemo(() => getChartOptions(selectedTab), [selectedTab])

  return (
    <section className='rounded-5xl flex min-h-[400px] w-full flex-col gap-2 border p-4'>
      <div className='flex flex-wrap items-center justify-between gap-1'>
        <h2 className='text-xl font-bold lg:text-3xl'>{title}</h2>
        <Menu
          tabsArray={['day', 'week', 'month', 'all']}
          setSelectedTab={(selectedTab: string) => {
            setSelectedTab(selectedTab as 'day' | 'week' | 'month' | 'all')
          }}
          selectedTab={selectedTab}
        />
      </div>
      {isLoading ? (
        <LoaderIcon className='mx-auto my-auto size-8 animate-spin text-primary' />
      ) : isError || !data ? (
        <div>Failed to load chart data.</div>
      ) : (
        <Line data={data} options={options} />
      )}
    </section>
  )
}

export default Chart
