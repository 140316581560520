import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import toast from 'react-hot-toast'

import MutationActionButton from '@/components/features/MutationActionButton'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IEmailWhitelist } from '@/types/email-whitelist.interface'

import formatId from '@/utils/formatId'

import CreateOrUpdate from './CreateOrUpdate'

export const columns: ColumnDef<IEmailWhitelist>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`@${row.original.email}`)
            toast.success('Email Copied to clipboard')
          }}
        >
          {row.original.email}
        </button>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      const date = new Date(row.original.created_at)
      if (date.toString() === 'Invalid Date') {
        return <p className='text-foreground/50'>-</p>
      }

      return <p className='text-foreground/50'>{format(date, 'yyyy.MM.dd')}</p>
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <CreateOrUpdate initial={row.original} />
          <MutationActionButton
            url={`/admin/email-whitelist/${row.original.id}`}
            description='Are you sure you want to delete this email from the whitelist?'
            permissionSlug='username_blacklist_update'
            successMessage='Email deleted successfully'
            errorMessage='Failed to delete email'
          />
        </div>
      )
    },
    enableSorting: false
  }
]
