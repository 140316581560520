import Page from '@/components/Page'
import { DataTable } from '@/components/ui/DataTable'

import CreateEDDRequest from './CreateEDDRequest'
import { columns } from './columns'
import { BANK_TABS } from '@/constants/tabs'

const BankEddRequestsPage: React.FC = () => {
  return (
    <Page
      permissionSlug='edd_requests_view'
      pageTitle='Edd Requests'
      header={<CreateEDDRequest />}
      tabs={BANK_TABS}
    >
      <DataTable url='admin/bank/edd' columns={columns} />
    </Page>
  )
}

export default BankEddRequestsPage
