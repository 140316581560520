import MetricsCards from '.'
import { useQuery } from '@tanstack/react-query'

import {
  IAccessCodesMetrics,
  IMetricCard
} from '@/types/metrics/metrics.interface'

import api from '@/api'
import { formatInteger } from '@/utils'

const formatAccessCodesMetrics = (data: IAccessCodesMetrics) => {
  return [
    {
      title: 'Total',
      value: formatInteger(data.total)
    },
    {
      title: 'Claimed',
      value: formatInteger(data.claimed.count),
      subtitle: `(${data.claimed.percentage}%)`
    },
    {
      title: 'Sent',
      value: formatInteger(data.sent.count),
      subtitle: `(${data.sent.percentage}%)`
    },
    {
      title: 'Sent Unclaimed',
      value: formatInteger(data.sentUnclaimed.count),
      subtitle: `(${data.sentUnclaimed.percentage}%)`
    }
  ] as IMetricCard[]
}

const AccessCodesMetrics: React.FC = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['access-codes-metrics'],
    queryFn: async () =>
      await api.get<IAccessCodesMetrics>('/admin/access-codes/metrics'),
    enabled: true,
    select: res => formatAccessCodesMetrics(res.data)
  })

  return (
    <MetricsCards
      isError={isError}
      isLoading={isLoading}
      data={data}
      cardsConfig={{
        count: 4
      }}
    />
  )
}

export default AccessCodesMetrics
