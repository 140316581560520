import { useMemo } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/Button'

import { IUser } from '@/types/account/user.interface'
import { KycStatus } from '@/types/enums'

import MutationActionButton, {
  MutationActionButtonProps
} from '../MutationActionButton'

import { Section } from './Section'

interface Props {
  noTitle?: boolean
  user: IUser
}

interface Action {
  label: string
  permissionSlug: string
  buttonProps: MutationActionButtonProps
}

const UserActions: React.FC<Props> = ({ user, noTitle = false }) => {
  const navigate = useNavigate()

  const isAllIpsBlocked = user?.ip_addresses?.every(ip => ip.is_blocked)

  const actions = useMemo(() => {
    const actions: Action[] = []

    if (user?.kyc_status !== KycStatus.UNVERIFIED) {
      actions.push({
        label: 'Reset Bank Account',
        permissionSlug: 'bank_reset_account',
        buttonProps: {
          url: `/admin/users/${user.id}/bank/reset`,
          method: 'POST',
          successMessage: 'Bank account reset',
          errorMessage: 'Failed to reset bank account',
          description:
            'Relink STBA account to ZH account (if it exists). This action does remove all payment methods and clears activity history',
          title: 'Reset Bank Account',
          buttonTitle: 'Reset',
          buttonClassName: 'w-1/3'
        }
      })
    }

    if (user?.avatar) {
      actions.push({
        label: 'Remove Avatar',
        permissionSlug: 'users_update',
        buttonProps: {
          url: `/admin/users/${user.id}/update-avatar`,
          method: 'PATCH',
          successMessage: 'Avatar removed',
          errorMessage: 'Failed to remove avatar',
          description:
            'This will remove the user avatar. The user can upload a new one',
          title: 'Remove Avatar',
          buttonTitle: 'Remove',
          buttonClassName: 'w-1/3'
        }
      })
    }

    if (user?.blocked) {
      actions.push({
        label: 'Unblock Account',
        permissionSlug: 'users_handle_block',
        buttonProps: {
          url: `/admin/users/${user.id}/handle-block`,
          method: 'PUT',
          successMessage: 'User unblocked',
          errorMessage: 'Failed to unblock user',
          description: 'Unblock user to allow using the account',
          title: 'Unblock',
          buttonTitle: 'Unblock',
          buttonClassName: 'w-1/3'
        }
      })
    } else {
      actions.push({
        label: 'Block Account',
        permissionSlug: 'users_handle_block',
        buttonProps: {
          url: `/admin/users/${user.id}/handle-block`,
          method: 'PUT',
          successMessage: 'User blocked',
          errorMessage: 'Failed to block user',
          description: 'Block user to prevent using the account',
          title: 'Block',
          buttonTitle: 'Block',
          buttonClassName: 'w-1/3'
        }
      })
    }

    if (user.ip_addresses && user.ip_addresses.length > 0) {
      if (isAllIpsBlocked) {
        actions.push({
          label: "Unblock IP's",
          permissionSlug: 'users_handle_block',
          buttonProps: {
            url: `/admin/users/${user.id}/handle-block-ip`,
            method: 'PUT',
            successMessage: 'IPs unblocked',
            errorMessage: 'Failed to unblock IPs',
            description: 'Unblock user IP addresses',
            title: 'Unblock IPs',
            buttonTitle: 'Unblock IPs',
            buttonClassName: 'w-1/3'
          }
        })
      } else {
        actions.push({
          label: "Block IP's",
          permissionSlug: 'users_handle_block',
          buttonProps: {
            url: `/admin/users/${user.id}/handle-block-ip`,
            method: 'PUT',
            successMessage: 'IPs blocked',
            errorMessage: 'Failed to block IPs',
            description: 'Block user IP addresses',
            title: 'Block IPs',
            buttonTitle: 'Block IPs',
            buttonClassName: 'w-1/3'
          }
        })
      }
    }

    if (user?.is_two_factor_enabled) {
      actions.push({
        label: 'Reset 2FA',
        permissionSlug: 'users_reset_2fa',
        buttonProps: {
          url: `/admin/users/${user.id}/reset-2fa`,
          method: 'PUT',
          successMessage: '2FA reset',
          errorMessage: 'Failed to reset 2FA',
          description: 'Reset 2FA for the user',
          title: 'Reset 2FA',
          buttonTitle: 'Reset 2FA',
          buttonClassName: 'w-1/3'
        }
      })
    }

    actions.push({
      label: 'Send OTP',
      permissionSlug: 'users_send_otp',
      buttonProps: {
        url: `/admin/users/${user.id}/send-otp`,
        method: 'PUT',
        successMessageShown: false,
        onSuccess: data => {
          const toastId = toast.success(
            <div className='flex flex-col gap-2'>
              <p>
                OTP sent to @{user?.username} successfully.
                <br />
                OTP: <b className='text-lg font-bold'>{data as string}</b>
                <br />
                Click close to copy the OTP.
              </p>
              <Button
                variant={'destructive'}
                onClick={() => {
                  navigator.clipboard.writeText(data as string)
                  toast.dismiss(toastId)
                }}
                className='self-end'
              >
                Close
              </Button>
            </div>,
            {
              position: 'top-center',
              duration: 100000
            }
          )
        },
        errorMessage: 'Failed to send OTP',
        description:
          'Send OTP to the user. You can use this to verify the user (you will get same OTP as the user)',
        title: 'Send OTP',
        buttonTitle: 'Send OTP',
        buttonClassName: 'w-1/3'
      }
    })

    if (user?.withdrawals_paused) {
      actions.push({
        label: 'Resume Withdrawals',
        permissionSlug: 'users_handle_withdraw',
        buttonProps: {
          url: `/admin/users/${user.id}/handle-withdrawal`,
          method: 'PUT',
          successMessage: 'Withdrawals resumed',
          errorMessage: 'Failed to resume withdrawals',
          description: 'Resume withdrawals for the user',
          title: 'Resume Withdrawals',
          buttonTitle: 'Resume',
          buttonClassName: 'w-1/3'
        }
      })
    } else {
      actions.push({
        label: 'Pause Withdrawals',
        permissionSlug: 'users_handle_withdraw',
        buttonProps: {
          url: `/admin/users/${user.id}/handle-withdrawal`,
          method: 'PUT',
          successMessage: 'Withdrawals paused',
          errorMessage: 'Failed to pause withdrawals',
          description: 'Pause withdrawals for the user',
          title: 'Pause Withdrawals',
          buttonTitle: 'Pause',
          buttonClassName: 'w-1/3'
        }
      })
    }

    if (user?.is_deleted) {
      actions.push({
        label: 'Restore Account',
        permissionSlug: 'users_soft_delete',
        buttonProps: {
          url: `/admin/users/${user.id}/handle-delete`,
          method: 'PUT',
          successMessage: 'User restored',
          errorMessage: 'Failed to restore user',
          description: 'Restore user account from soft delete',
          title: 'Restore Account',
          buttonTitle: 'Restore (soft)',
          buttonClassName: 'w-1/3'
        }
      })
    } else {
      actions.push({
        label: 'Delete (soft)',
        permissionSlug: 'users_soft_delete',
        buttonProps: {
          url: `/admin/users/${user.id}/handle-delete`,
          method: 'PUT',
          successMessage: 'User deleted (soft)',
          errorMessage: 'Failed to delete user',
          description:
            'Delete user account (soft delete, it still exists in the database and can be restored, but it is not visible in the app for the user)',
          title: 'Delete (soft)',
          buttonTitle: 'Delete (soft)',
          buttonClassName: 'w-1/3'
        }
      })
    }

    actions.push({
      label: 'Delete (hard)',
      permissionSlug: 'users_hard_delete',
      buttonProps: {
        url: `/admin/users/${user.id}`,
        method: 'DELETE',
        successMessage: 'User deleted (hard)',
        errorMessage: 'Failed to delete user',
        description:
          'Delete user account (hard delete, it is removed from the database and cannot be restored. Use with caution)',
        title: 'Delete (hard)',
        buttonTitle: 'Delete (hard)',
        buttonClassName: 'w-1/3',
        onSuccess: () => {
          navigate('/')
        }
      }
    })

    return actions
  }, [user, isAllIpsBlocked])

  return (
    <Section
      title={noTitle ? undefined : 'Actions'}
      options={actions.map(action => ({
        label: action.label,
        permissionSlug: action.permissionSlug,
        rightContent: <MutationActionButton {...action.buttonProps} />
      }))}
    />
  )
}

export default UserActions
