import Page from '@/components/Page'
import { DataTable } from '@/components/ui/DataTable'

import CreateApk from './CreateApk'
import CreateOrUpdateDeployment from './CreateOrUpdate'
import { columns } from './columns'

const DeploymentsPage: React.FC = () => {
  return (
    <Page
      permissionSlug='deployments_view'
      pageTitle='Deployments'
      header={
        <div className='ml-auto flex flex-row gap-1 md:gap-2'>
          <CreateApk />
          <CreateOrUpdateDeployment />
        </div>
      }
    >
      <DataTable
        columns={columns}
        url='/admin/deployment'
        totalText='deployments'
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}

export default DeploymentsPage
