import Page from '@/components/Page'
import { DataTable } from '@/components/ui/DataTable'

import CreateOrUpdateConfiguration from './CreateOrUpdateConfiguration'
import { columns } from './columns'
import { ORGANIZATION_TABS } from '@/constants/tabs'

const ConfigurationsPage: React.FC = () => {
  return (
    <Page
      permissionSlug='organization_view'
      pageTitle='Configurations'
      tabs={ORGANIZATION_TABS}
      header={<CreateOrUpdateConfiguration />}
    >
      <DataTable
        url='admin/configuration'
        totalText='configurations'
        columns={columns}
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}

export default ConfigurationsPage
