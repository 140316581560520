import Page from '@/components/Page'
import { DataTable } from '@/components/ui/DataTable'

import CreateOrUpdateIp from './CreateOrUpdateIp'
import { columns } from './columns'
import { ORGANIZATION_TABS } from '@/constants/tabs'

const IpAddressesPage: React.FC = () => {
  return (
    <Page
      permissionSlug='ip_addresses_view'
      pageTitle='Ip Addresses'
      tabs={ORGANIZATION_TABS}
      header={<CreateOrUpdateIp />}
    >
      <DataTable
        url='admin/ips'
        totalText="ip's"
        columns={columns}
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}

export default IpAddressesPage
