import * as SwitchPrimitives from '@radix-ui/react-switch'
import * as React from 'react'

import { cn } from '@/utils'

const SwitchComponent = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-green-500 data-[state=unchecked]:bg-input',
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0'
      )}
    />
  </SwitchPrimitives.Root>
))
SwitchComponent.displayName = SwitchPrimitives.Root.displayName

interface Props {
  checked: boolean
  onCheckedChange: (checked: boolean) => void
  title: string
}

const Switch: React.FC<Props> = ({ checked, onCheckedChange, title }) => {
  return (
    <label className='flex items-center justify-between gap-2 text-base font-medium text-foreground/50'>
      <p>{title}</p>
      <SwitchComponent checked={checked} onCheckedChange={onCheckedChange} />
    </label>
  )
}

export default Switch
