import { ColumnDef } from '@tanstack/react-table'

import MutationActionButton from '@/components/features/MutationActionButton'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IRole } from '@/types/roles/role.interface'

import CreateOrUpdateRole from '@/pages/Organization/Roles/CreateOrUpdateRole'

export const columns: ColumnDef<IRole>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Name' />
    },
    cell: ({ row }) => {
      if (row.original.name) {
        return <p className='text-foreground/50'>{row.original.name}</p>
      } else {
        return <p className='text-foreground/50'>N/A</p>
      }
    }
  },
  {
    accessorKey: 'description',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Description' />
    },
    cell: ({ row }) => {
      if (row.original.description) {
        return <p className='text-foreground/50'>{row.original.description}</p>
      } else {
        return <p className='text-foreground/50'>N/A</p>
      }
    }
  },
  {
    accessorKey: 'roles_count',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Count' />
    },
    cell: ({ row }) => {
      return row.original.administrators.length
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      if (row.original.is_super_role) return null
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <CreateOrUpdateRole initial={row.original} />
          <MutationActionButton
            url={`/admin/roles/${row.original.id}`}
            description='Are you sure you want to delete this role?'
            permissionSlug='roles_delete'
            successMessage='Role deleted successfully'
            errorMessage='Error deleting role'
          />
        </div>
      )
    },
    enableSorting: false
  }
]
