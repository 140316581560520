import { Link } from 'react-router-dom'

import ProtectedComponent from '../Protected'

import { ITab } from '@/constants/tabs'
import { cn } from '@/utils'

interface Props {
  setSelectedTab: (tab: string) => void
  selectedTab: string
  tabsArray?: string[]
  tabs?: ITab[]
}

const Menu: React.FC<Props> = ({
  setSelectedTab,
  selectedTab,
  tabsArray,
  tabs
}) => {
  if (tabsArray) {
    return (
      <section className='flex flex-wrap gap-x-2 gap-y-1 lg:gap-x-4 lg:gap-y-2'>
        {tabsArray.map(tab => (
          <button
            key={tab}
            onClick={() => setSelectedTab(tab)}
            className={cn(
              'select-none text-xl font-bold lg:text-2xl xl:text-3xl',
              {
                'text-primary': tab === selectedTab,
                'text-white text-opacity-50 hover:text-opacity-80 active:text-opacity-100':
                  !(tab === selectedTab)
              }
            )}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </section>
    )
  }
  if (tabs) {
    return (
      <section className='flex flex-wrap gap-x-2 gap-y-1 lg:gap-x-4 lg:gap-y-2'>
        {tabs.map(tab => (
          <ProtectedComponent key={tab.url} permissionSlug={tab.permissionSlug}>
            <Link
              to={tab.url}
              className={cn(
                'select-none text-xl font-bold lg:text-2xl xl:text-3xl',
                {
                  'text-primary': tab.url === selectedTab,
                  'text-white text-opacity-50 hover:text-opacity-80 active:text-opacity-100':
                    tab.url !== selectedTab
                }
              )}
            >
              {tab.name}
            </Link>
          </ProtectedComponent>
        ))}
      </section>
    )
  }
  return null
}

export default Menu
