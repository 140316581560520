import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IBankTransfer } from '@/types/bank-transfer/bank-transfer.interface'
import { BankAsset } from '@/types/enums'

import formatId from '@/utils/formatId'

import { formatNumber, msatsToSats } from '@/utils'

export const transfersColumns: ColumnDef<IBankTransfer>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'provider_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Provider ID' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.provider_id}`)
            toast.success('Provider ID Copied to clipboard')
          }}
        >
          {formatId(row.original.provider_id)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'type',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Type' />
    },
    cell: ({ row }) => {
      return <p>{row.original.type}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'asset',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Asset' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.asset}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'side',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Side' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.side}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Status' />
    },
    cell: ({ row }) => {
      return <p>{row.original.status}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'failure_code',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Failure Code' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.failure_code || 'N/A'}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'amount',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Amount' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.asset === BankAsset.USD
            ? `$${formatNumber(String(row.original.amount))}`
            : `${msatsToSats(String(row.original.amount))} sats`}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'estimated_amount',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Estimated Amount' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          ${formatNumber(String(row.original.estimated_amount))}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'fee',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Fee' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {msatsToSats(String(row.original.fee)).toString()} sats
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'provider_updated_at',
    header: ({ column }) => {
      return (
        <DataTableColumnHeader column={column} name='Provider Updated At' />
      )
    },
    cell: ({ row }) => {
      return row.original.provider_updated_at ? (
        <p className='text-foreground/50'>
          {new Date(row.original.provider_updated_at).toLocaleDateString(
            'en-US',
            {
              hour: '2-digit',
              minute: '2-digit',
              day: 'numeric',
              month: 'short'
            }
          )}
        </p>
      ) : (
        <p className='text-foreground/50'>N/A</p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'transaction_hash',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Transaction Hash' />
    },
    cell: ({ row }) => {
      if (row.original.transaction_hash) {
        return (
          <button
            onClick={() => {
              navigator.clipboard.writeText(`${row.original.transaction_hash}`)
              toast.success('Hash Copied to clipboard')
            }}
          >
            {formatId(row.original.transaction_hash)}
          </button>
        )
      }
      return <p className='text-foreground/50'>N/A</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'bolt11',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Bolt11' />
    },
    cell: ({ row }) => {
      if (row.original.bolt11) {
        return (
          <button
            onClick={() => {
              navigator.clipboard.writeText(`${row.original.bolt11}`)
              toast.success('Bolt11 Copied to clipboard')
            }}
          >
            {formatId(row.original.bolt11)}
          </button>
        )
      }
      return <p className='text-foreground/50'>N/A</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'receiver',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Receiver' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>{row.original.receiver || 'N/A'}</p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {new Date(row.original.created_at).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    },
    enableSorting: false
  }
]
