import { useQuery } from '@tanstack/react-query'
import { ChartData } from 'chart.js'
import 'chartjs-adapter-date-fns'
import React, { useState } from 'react'

import { IAccountsMetricsData } from '@/types/metrics-charts'

import Chart from './Chart'
import api from '@/api'

const AccountsMetricsChart: React.FC = () => {
  const [range, setRange] = useState<'day' | 'week' | 'month' | 'all'>('day')

  const { data, isLoading, isError } = useQuery({
    queryKey: ['accountsMetrics', range],
    queryFn: async () =>
      await api.get<IAccountsMetricsData>(
        `/admin/metrics/charts/accounts?range=${range}`
      ),
    enabled: true,
    select: res => {
      const rawData = res.data.data
      const labels = rawData.map(d => new Date(d.timestamp))

      const activeLastHour = rawData.map(d => d.active_last_hour)
      const activeLastDay = rawData.map(d => d.active_last_day)
      const activeLastWeek = rawData.map(d => d.active_last_week)
      const activeLastMonth = rawData.map(d => d.active_last_month)
      const total = rawData.map(d => d.total)
      const totalReferred = rawData.map(d => d.total_referred)
      const totalDownloads = rawData.map(d => d.total_downloads)
      const totalWaitlisted = rawData.map(d => d.total_waitlisted)
      const totalSatoshiPlus = rawData.map(d => d.total_satoshi_plus)

      const chartData: ChartData<'line'> = {
        labels,

        datasets: [
          {
            label: 'Active Last Hour',
            data: activeLastHour,
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Active Last Day',
            data: activeLastDay,
            fill: false,
            borderColor: 'rgba(255,99,132,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Active Last Week',
            data: activeLastWeek,
            fill: false,
            borderColor: 'rgba(255,159,64,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Active Last Month',
            data: activeLastMonth,
            fill: false,
            borderColor: 'rgba(255,205,86,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Total',
            data: total,
            fill: false,
            borderColor: 'rgba(54,162,235,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Total Referred',
            data: totalReferred,
            fill: false,
            borderColor: 'rgba(153,102,255,1)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Total Downloads',
            data: totalDownloads,
            fill: false,
            borderColor: 'rgba(255,159,64,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          },
          {
            label: 'Total Waitlisted',
            data: totalWaitlisted,
            fill: false,
            borderColor: 'rgba(75,192,192,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5
          },
          {
            label: 'Total Satoshi Plus',
            data: totalSatoshiPlus,
            fill: false,
            borderColor: 'rgba(255,99,132,0.5)',
            tension: 0.1,
            pointRadius: 3,
            pointHoverRadius: 5,
            hidden: true
          }
        ]
      }

      return chartData
    }
  })

  return (
    <Chart
      title='Accounts Metrics'
      setSelectedTab={setRange}
      selectedTab={range}
      isLoading={isLoading}
      isError={isError}
      data={data}
    />
  )
}

export default AccountsMetricsChart
