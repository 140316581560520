import { ColumnDef } from '@tanstack/react-table'
import { formatDate } from 'date-fns'
import toast from 'react-hot-toast'

import MutationActionButton from '@/components/features/MutationActionButton'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IUser } from '@/types/account/user.interface'

import formatId from '@/utils/formatId'

export const columns: ColumnDef<
  IUser & { account_group_id: string; account_group_membership_id: string }
>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return formatId(row.original.id)
    },
    enableSorting: false
  },
  {
    accessorKey: 'display_name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Display Name' />
    },
    cell: ({ row }) => {
      if (row.original.display_name) {
        return <p>{row.original.display_name}</p>
      } else {
        return <p className='text-foreground/50'>-</p>
      }
    }
  },
  {
    accessorKey: 'username',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='@user' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`@${row.original.username}`)
            toast.success('Username Copied to clipboard')
          }}
          className='text-foreground/50'
        >
          @{row.original.username}
        </button>
      )
    }
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(row.original.email)
            toast.success('Email copied to clipboard')
          }}
          className='text-foreground/50'
        >
          {row.original.email}
        </button>
      )
    }
  },
  {
    accessorKey: 'avatar',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Avatar' />
    },
    cell: ({ row }) => {
      return row.original.avatar ? (
        <div
          className='h-[30px] w-[30px] overflow-hidden rounded-full bg-cover bg-center'
          style={{
            backgroundImage: `url(${row.original.avatar})`
          }}
        />
      ) : (
        <div className='h-[30px] w-[30px] rounded-full bg-white/20' />
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Date Joined' />
    },
    cell: ({ row }) => {
      const date = new Date(row.original.created_at)
      if (date.toString() === 'Invalid Date') {
        return <p className='text-foreground/50'>-</p>
      }

      return (
        <p className='text-foreground/50'>{formatDate(date, 'yyyy.MM.dd')}</p>
      )
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Actions' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <MutationActionButton
            url={`/admin/account-groups/${row.original.account_group_id}`}
            body={{
              accountGroupMembershipIds: [
                row.original.account_group_membership_id
              ]
            }}
            method='PUT'
            buttonTitle='Remove'
            description='Are you sure you want to remove this user from the account group?'
            permissionSlug='account_groups_update'
            successMessage='User removed from account group successfully'
            errorMessage='Failed to remove user from account group'
          />
        </div>
      )
    },
    enableSorting: false
  }
]
