import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field from '@/components/ui/Field'

import {
  ICreateOrUpdateUsernameBlacklist,
  IUsernameBlacklist
} from '@/types/username-blacklist/username-blacklist.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  initial?: IUsernameBlacklist
}

const CreateOrUpdate: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateUsernameBlacklist>({
    mode: 'onChange',
    defaultValues: initial || {
      username: ''
    }
  })

  useEffect(() => {
    reset(
      initial || {
        username: ''
      }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-category' : 'add-category'],
    mutationFn: (data: ICreateOrUpdateUsernameBlacklist) =>
      api(
        initial
          ? `/admin/username-blacklist/${initial.id}`
          : '/admin/username-blacklist',
        {
          method: initial ? 'PATCH' : 'POST',
          data
        }
      ),
    onSuccess: () => {
      toast.success(
        `Username Blaclist item ${initial ? 'updated' : 'created'} successfully!`
      )
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          `Failed to ${initial ? 'update' : 'create'} Username Blaclist item`
      )
    }
  })

  const onSubmit: SubmitHandler<
    ICreateOrUpdateUsernameBlacklist
  > = async data => mutate(data)

  return (
    <ProtectedComponent permissionSlug={'username_blacklist_update'}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={initial ? 'edit' : 'create'}>
            {initial ? 'Edit' : 'Add Username'}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {initial ? 'Edit Username' : 'Add Username to Blacklist'}
            </DialogTitle>
            <DialogDescription>
              {initial
                ? 'Edit the username details'
                : 'Add a new username to blacklist'}
            </DialogDescription>
          </DialogHeader>
          <form
            className='mt-4 flex flex-col gap-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              placeholder='username'
              {...register('username', {
                required: 'Username is required',
                minLength: {
                  value: 3,
                  message: 'Username must be at least 3 characters'
                }
              })}
              error={errors.username?.message}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default CreateOrUpdate
