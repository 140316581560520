import { CreateAxiosDefaults } from 'axios'

export const apiConfig: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 100000, // 100 seconds
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
}
