import { Link } from 'react-router-dom'

import { buttonVariants } from '@/components/ui/Button'

import { cn } from '@/utils'

const NotFoundPage: React.FC = () => {
  return (
    <section className='h-[100vh] w-[100vw] bg-gray800'>
      <div className='flex h-full flex-col items-center justify-center'>
        <h1 className='text-6xl font-bold text-foreground/50'>404</h1>
        <p className='text-2xl font-semibold text-foreground/50'>
          Page not found
        </p>
        <Link
          to='/'
          className={cn(
            buttonVariants({
              variant: 'default',
              size: 'lg'
            }),
            'mt-10'
          )}
        >
          Go back home
        </Link>
      </div>
    </section>
  )
}

export default NotFoundPage
