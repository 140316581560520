import { useQuery } from '@tanstack/react-query'

import Icon from '@/components/ui/Icon'

import api from '@/api'
import { formatInteger } from '@/utils'

const AvgFeeAndBtcPrice: React.FC = () => {
  return (
    <section className='ml-auto mr-10 hidden flex-row gap-5 md:flex'>
      <GetBtcPrice />
      <GetAvgFee />
    </section>
  )
}

export default AvgFeeAndBtcPrice

const GetAvgFee: React.FC = () => {
  const {
    data: avgFee,
    isError: isAvgFeeError,
    isLoading: isAvgFeeLoading
  } = useQuery({
    queryKey: ['avgFee'],
    queryFn: async () => {
      const resp = await api.get<string>('/admin/avg-fee')

      return resp.data
    }
  })

  if (isAvgFeeError || isAvgFeeLoading) return null

  return (
    <div className='flex flex-row items-center gap-1'>
      <span className='text-sm font-semibold text-foreground'>
        {avgFee} sats/vB
      </span>
      <span className='text-sm font-semibold text-foreground/50'>Avg Fee</span>
    </div>
  )
}

const GetBtcPrice: React.FC = () => {
  const {
    data: btcPrice,
    isError: isBtcError,
    isLoading: isBtcLoading
  } = useQuery({
    queryKey: ['btcPrice'],
    queryFn: async () => {
      const resp = await api.get<string>('/admin/btc-price')

      return resp.data
    }
  })

  if (isBtcError || isBtcLoading) return null

  return (
    <div className='flex flex-row items-center gap-1'>
      <Icon className='h-6 w-6' icon='Bitcoin' />
      <span className='text-sm font-semibold text-foreground'>
        ${formatInteger(btcPrice, '00,000')}
      </span>
    </div>
  )
}
