import { forwardRef } from 'react'

interface Props {
  avatar: string | null
  name: string
  nameClassName?: string
  onPress?: () => void
}

const UserCard = forwardRef<HTMLButtonElement, Props>(
  ({ avatar, name, nameClassName, onPress }, ref) => {
    return (
      <button
        ref={ref}
        className='flex items-center gap-2 overflow-hidden whitespace-nowrap'
        onClick={onPress}
      >
        {avatar ? (
          <div
            className='size-8 flex-none rounded-full bg-cover bg-center'
            style={{
              backgroundImage: `url(${avatar})`
            }}
          />
        ) : (
          <div className='size-8 flex-none rounded-full bg-foreground/20' />
        )}
        <p className={nameClassName} title={name}>
          {name}
        </p>
      </button>
    )
  }
)

UserCard.displayName = 'UserCard'

export default UserCard
