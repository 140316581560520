import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IBankTrade } from '@/types/bank-trade/bank-trade.interface'
import { BankSide } from '@/types/enums'

import formatId from '@/utils/formatId'

import { formatNumber } from '@/utils'

export const columns: ColumnDef<IBankTrade>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'provider_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Provider ID' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.provider_id}`)
            toast.success('Provider ID Copied to clipboard')
          }}
        >
          {formatId(row.original.provider_id)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'user_id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='User ID' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(
              `${row.original.bank_customer.account.id}`
            )
            toast.success('User ID Copied to clipboard')
          }}
        >
          {formatId(row.original.bank_customer.account.id)}
        </button>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'bank_customer.account',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account' />
    },
    cell: ({ row }) => {
      return (
        <UserDetailsModal
          fromOtherPage
          user={row.original.bank_customer.account}
        />
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'bank_customer.account.email',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Email' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.bank_customer.account.email}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'asset',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Asset' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.asset}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'side',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Side' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.side}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'status',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Status' />
    },
    cell: ({ row }) => {
      return <p>{row.original.status}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'symbol',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Symbol' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.symbol}</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'failure_code',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Failure Code' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {row.original.failure_code || 'N/A'}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'deliver_amount',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Amount' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {`$${formatNumber(String(row.original.side === BankSide.BUY ? row.original.deliver_amount : row.original.receive_amount))}`}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'fee',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Fee' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {`$${formatNumber(String(row.original.fee))}`}
        </p>
      )
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {new Date(row.original.created_at).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    },
    enableSorting: false
  }
]
