import { cn } from '.'

import Icon from '@/components/ui/Icon'

import { KycStatus } from '@/types/enums'

export const returnKycIcon = (kycStatus: KycStatus, className?: string) => {
  return (
    <Icon
      icon={
        kycStatus === KycStatus.UNVERIFIED
          ? 'Unverified'
          : kycStatus === KycStatus.FAILED
            ? 'Rejected'
            : kycStatus === KycStatus.STARTED
              ? 'InProgress'
              : 'Approved'
      }
      className={cn('size-6', className)}
    />
  )
}
