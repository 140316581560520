import { ChevronLeft } from 'lucide-react'
import { useNavigate } from 'react-router-dom'

import { Button } from './Button'

interface Props {
  href?: string
}

const BackButton: React.FC<Props> = ({ href }) => {
  const navigation = useNavigate()

  return (
    <Button
      className='flex w-fit items-center gap-0.5 whitespace-nowrap text-lg font-semibold lg:text-xl'
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        navigation(href ? href : (-1 as any))
      }}
      variant={'ghost'}
      rounded={'md'}
    >
      <ChevronLeft className='size-6' /> Go back
    </Button>
  )
}

export default BackButton
