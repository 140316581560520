import { useQuery } from '@tanstack/react-query'
import React from 'react'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue
} from '@/components/ui/Select'

import { IRole } from '@/types/roles/role.interface'

import api from '@/api'

interface Props {
  roleId?: string
  setRoleId: (id?: string) => void
}

const RoleSelect: React.FC<Props> = ({ roleId, setRoleId }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      const response = await api('admin/roles', {
        method: 'GET',
        params: {
          isFullList: true
        }
      })
      return response.data
    }
  })

  return (
    <Select
      onValueChange={val => setRoleId(val || undefined)}
      defaultValue={roleId}
    >
      <SelectTrigger className='w-full justify-between border-2 !px-5 !py-8 !font-bold dark:border-gray500/20 dark:bg-gray800'>
        <SelectValue placeholder='Select Role' />
      </SelectTrigger>
      <SelectContent side='top' className='z-[60] w-full'>
        <SelectGroup>
          {isLoading ? (
            <SelectLabel>Loading...</SelectLabel>
          ) : isError || !data ? (
            <SelectLabel>Error loading roles</SelectLabel>
          ) : (
            <>
              <SelectLabel>Roles</SelectLabel>
              <SelectSeparator />
              {data.map((role: IRole) => (
                <SelectItem key={role.id} value={role.id}>
                  {role.name}
                </SelectItem>
              ))}
            </>
          )}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

export default RoleSelect
