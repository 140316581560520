import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field from '@/components/ui/Field'

import { ICreateAccountGroup } from '@/types/account-groups/account-group.interface'

import api from '@/api'

export const CreateGroup = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['createAccountGroup'],
    mutationFn: async (data: ICreateAccountGroup) => {
      const resp = await api.post(`/admin/account-groups`, {
        ...data
      })

      return resp.data
    },
    onSuccess: async () => {
      reset()
      queryClient.refetchQueries()
      setIsOpen(false)
      toast.success('Group successfully created!', {
        id: 'account-groups'
      })
    },
    onError: error => {
      toast.error(error.message, {
        id: 'account-groups'
      })
    }
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateAccountGroup>({
    defaultValues: {
      name: ''
    }
  })

  const onSubmit = (data: ICreateAccountGroup) => {
    mutate(data)
  }

  return (
    <ProtectedComponent permissionSlug={'account_groups_create'}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={'create'}>Add Group</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create Account Group</DialogTitle>
            <DialogDescription>Create a new account group</DialogDescription>
          </DialogHeader>
          <form
            className='flex w-full flex-col gap-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              title='Name'
              placeholder='Group Name'
              {...register('name', {
                required: 'Group Name is required'
              })}
              error={errors.name?.message}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}
