import { ChartOptions } from 'chart.js'

export const getChartOptions = (
  range: 'day' | 'week' | 'month' | 'all'
): ChartOptions<'line'> => {
  let unit: 'hour' | 'day' | 'month' = 'day'

  switch (range) {
    case 'day':
      unit = 'hour'
      break
    case 'week':
    case 'month':
      unit = 'day'
      break
    case 'all':
      unit = 'month'
      break
  }

  return {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false
    },
    hover: {
      mode: 'index',
      intersect: false
    },
    onHover: (event, chartElement) => {
      if (chartElement.length) {
        ;(event.native?.target as HTMLCanvasElement).style.cursor = 'pointer'
      } else {
        ;(event.native?.target as HTMLCanvasElement).style.cursor = 'default'
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: '#FFFFFF'
        }
      },
      y: {
        beginAtZero: false,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: '#FFFFFF'
        }
      }
    },
    plugins: {
      legend: {
        onHover: event => {
          ;(event.native?.target as HTMLCanvasElement).style.cursor = 'pointer'
        },
        onLeave: event => {
          ;(event.native?.target as HTMLCanvasElement).style.cursor = 'default'
        },
        labels: {
          color: 'rgba(255, 255, 255, 0.9)'
        }
      },
      tooltip: {
        mode: 'index',
        intersect: false
      }
    }
  }
}
