import { ChevronDown } from 'lucide-react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/Button'
import Icon from '@/components/ui/Icon'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/Popover'

import { useActions } from '@/hooks/use-actions'
import { useAuth } from '@/hooks/use-auth'

import AvgFeeAndBtcPrice from './AvgFeeAndBtcPrice'
import { cn } from '@/utils'

export const Header = () => {
  const { admin } = useAuth()
  const { logout } = useActions()
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleSignOut = () => {
    logout()
    navigate('/login', { replace: true })
  }

  return (
    <header className='flex h-full items-center justify-between border-b border-white/15 p-4 pl-7'>
      <div className='flex items-center gap-[10px]'>
        <Icon icon='Logo' className='text-foreground/30' />
        <h1 className='text-lg font-bold text-white'>Admin</h1>
      </div>

      {!admin && (
        <Link
          to='/login'
          className='cursor-pointer text-sm text-white/50 hover:text-white'
        >
          Sign in
        </Link>
      )}
      {admin && (
        <>
          <AvgFeeAndBtcPrice />
          <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
              <Button
                variant={'ghost'}
                rounded={'md'}
                className='gap-2 font-semibold text-foreground/50'
              >
                <div className='size-8 overflow-hidden rounded-full bg-foreground/10'>
                  {admin.avatar && <img src={admin.avatar} alt='Avatar' />}
                </div>
                {admin.email}
                <ChevronDown
                  className={cn('size-4', {
                    'rotate-180': isOpen
                  })}
                />
              </Button>
            </PopoverTrigger>
            <PopoverContent className='flex flex-col gap-2 p-2'>
              {[
                {
                  icon: (
                    <Icon icon='Settings' className='size-4 fill-foreground' />
                  ),
                  label: 'Settings',
                  onClick: () => {
                    navigate('/settings')
                    setIsOpen(false)
                  }
                },
                {
                  icon: <Icon icon='Exit' className='size-4 fill-foreground' />,
                  label: 'Sign out',
                  onClick: () => {
                    handleSignOut()
                    setIsOpen(false)
                  }
                }
              ].map((item, i: number) => (
                <Button
                  key={i}
                  variant={'outline'}
                  rounded={'none'}
                  className='w-full justify-start gap-2'
                  onClick={item.onClick}
                >
                  {item.icon}
                  {item.label}
                </Button>
              ))}
            </PopoverContent>
          </Popover>
        </>
      )}
    </header>
  )
}
