import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import Page from '@/components/Page'
import UserDetailsModal from '@/components/features/UserDetails/Modal'
import BackButton from '@/components/ui/ButtonBack'
import { DataTable } from '@/components/ui/DataTable'

import { IUser } from '@/types/account/user.interface'

import AddReferral from './AddReferral'
import { columns } from './columns'
import api from '@/api'
import { REFER_TABS } from '@/constants/tabs'

const ReferralsByAccountIdPage: React.FC = () => {
  const { id } = useParams()

  const { data, isLoading, isError } = useQuery({
    queryKey: ['user', id],
    queryFn: async () => {
      const resp = await api<IUser>(`/admin/users/${id}`)

      return resp.data
    }
  })

  if (!id) {
    return <Page title='No ID provided' pageTitle='No ID provided' />
  }

  if (isError || !data) {
    return <Page title='Error' pageTitle='Error loading Refer Account' />
  }

  return (
    <Page
      permissionSlug='refer_view'
      title={
        isLoading ? (
          'Loading...'
        ) : (
          <UserDetailsModal fromOtherPage user={data} />
        )
      }
      pageTitle='View Referrals'
      header={
        <div className='flex w-full flex-wrap justify-between gap-2'>
          <BackButton href={REFER_TABS[0].url} />
          <AddReferral referId={id} />
        </div>
      }
    >
      <DataTable
        url={`/admin/refer/${id}/referrals`}
        columns={columns}
        totalText='accounts'
      />
    </Page>
  )
}

export default ReferralsByAccountIdPage
