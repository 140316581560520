import Page from '@/components/Page'
import { DataTable } from '@/components/ui/DataTable'

import { CreateGroup } from './CreateGroup'
import { columns } from './columns'
import { USERS_TABS } from '@/constants/tabs'

const AccountGroupsPage: React.FC = () => {
  return (
    <Page
      permissionSlug='account_groups_view'
      pageTitle='Account Groups'
      header={<CreateGroup />}
      tabs={USERS_TABS}
    >
      <DataTable
        url='/admin/account-groups'
        columns={columns}
        totalText='account-groups'
      />
    </Page>
  )
}

export default AccountGroupsPage
