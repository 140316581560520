import { ColumnDef } from '@tanstack/react-table'

import MutationActionButton from '@/components/features/MutationActionButton'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IConfiguration } from '@/types/configurations/configuration.interface'

import CreateOrUpdateConfiguration from './CreateOrUpdateConfiguration'

export const columns: ColumnDef<IConfiguration>[] = [
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Name' />
    },
    cell: ({ row }) => row.original.name
  },
  {
    accessorKey: 'description',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Description' />
    },
    cell: ({ row }) => {
      if (row.original.description) {
        return <p>{row.original.description}</p>
      } else {
        return <p className='text-foreground/50'>N/A</p>
      }
    }
  },
  {
    accessorKey: 'value',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Value' />
    },
    cell: ({ row }) => {
      if (row.original.name) {
        return <p className='text-foreground/50'>{row.original.value}</p>
      } else {
        return <p className='text-foreground/50'>N/A</p>
      }
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <CreateOrUpdateConfiguration initial={row.original} />
          <MutationActionButton
            url={`/admin/configuration/${row.original.id}`}
            description='Are you sure you want to delete this configuration?'
            successMessage='Configuration deleted successfully'
            errorMessage='Failed to delete configuration'
            permissionSlug='configurations_delete'
          />
        </div>
      )
    },
    enableSorting: false
  }
]
