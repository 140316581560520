import Page from '@/components/Page'
import { DataTable } from '@/components/ui/DataTable'

import { SendNotification } from '@/pages/Notifications/SendNotification'
import { columns } from '@/pages/Notifications/columns'

const NotificationsPage: React.FC = () => {
  return (
    <Page
      permissionSlug='users_send_notifications'
      pageTitle='Notifications'
      header={<SendNotification />}
    >
      <DataTable
        url='/admin/notifications'
        columns={columns}
        totalText='notifications'
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}

export default NotificationsPage
