import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { Button } from '@/components/ui/Button'

interface ImageUploadProps {
  image: string
  onImage: (file: File) => void
}

export const AvatarUpload = ({ image, onImage }: ImageUploadProps) => {
  const [currentImage, setCurrentImage] = useState<string | undefined>(image)

  useEffect(() => {
    setCurrentImage(image)
  }, [image])

  const onSelectImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = false
    input.accept = 'image/png, image/jpeg, image/jpg, image/webp'

    const MAX_FILE_SIZE = 2 * 1024 * 1024 // 2MB

    input.onchange = () => {
      const file = input.files?.[0]
      if (file) {
        if (file.size > MAX_FILE_SIZE) {
          toast.error('File size is too large. Max file size is 2MB.')
          input.remove()
          return
        }

        const reader = new FileReader()
        reader.addEventListener('load', event => {
          const data = event?.target?.result
          if (data) {
            setCurrentImage(data as string)
            onImage(file)
          }
        })
        reader.readAsDataURL(file)
      }

      input.remove()
    }

    input.click()
  }

  return (
    <div className='flex flex-1 flex-col items-center justify-between gap-[10px] rounded-[10px] border-2 border-gray500/20 bg-gray800 p-[20px]'>
      <span className='text-[12px] font-bold leading-[15px] text-white'>
        Avatar
      </span>

      {currentImage ? (
        <div
          className='h-[70px] w-[70px] overflow-hidden rounded-full bg-cover bg-center'
          style={{
            backgroundImage: `url(${currentImage})`
          }}
        />
      ) : (
        <div className='h-[70px] w-[70px] overflow-hidden rounded-full bg-white/20' />
      )}

      {!currentImage ? (
        <Button variant={'create'} onClick={onSelectImage}>
          Add
        </Button>
      ) : (
        <Button variant={'edit'} onClick={onSelectImage}>
          Edit
        </Button>
      )}
    </div>
  )
}
