import { ColumnDef } from '@tanstack/react-table'
import toast from 'react-hot-toast'

import MutationActionButton from '@/components/features/MutationActionButton'
import UserDetailsModal from '@/components/features/UserDetails/Modal'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'
import Icon from '@/components/ui/Icon'

import { IIpAddress } from '@/types/ip-address/ip-address.interface'

import formatId from '@/utils/formatId'

import CreateOrUpdateIp from './CreateOrUpdateIp'
import { cn } from '@/utils'

export const columns: ColumnDef<IIpAddress>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'value',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Value' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.value}`)
            toast.success('Value Copied to clipboard')
          }}
        >
          {row.original.value}
        </button>
      )
    }
  },
  {
    accessorKey: 'bank_customer.account',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Account' />
    },
    cell: ({ row }) => {
      if (!row.original.account) {
        return <p className='text-foreground/50'>N/A</p>
      }

      return <UserDetailsModal fromOtherPage user={row.original.account} />
    },
    enableSorting: false
  },
  {
    accessorKey: 'is_blocked',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Blocked' />
    },
    cell: ({ row }) => {
      return (
        <p
          className={cn('text-foreground/50', {
            'text-foreground': row.original.is_blocked
          })}
        >
          {row.original.is_blocked ? (
            <Icon icon='Approved' className='size-6' />
          ) : (
            'Unblocked'
          )}
        </p>
      )
    }
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Created At' />
    },
    cell: ({ row }) => {
      return (
        <p className='text-foreground/50'>
          {new Date(row.original.created_at).toLocaleDateString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            day: 'numeric',
            month: 'short'
          })}
        </p>
      )
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <CreateOrUpdateIp initial={row.original} />
          <MutationActionButton
            url={`/admin/ips/${row.original.id}`}
            description='Are you sure you want to delete this ip address from the system?'
            permissionSlug='ip_addresses_delete'
            successMessage='Ip address deleted successfully'
            errorMessage='Failed to delete ip address'
          />
        </div>
      )
    },
    enableSorting: false
  }
]
