import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

import MutationActionButton from '@/components/features/MutationActionButton'
import { DataTableColumnHeader } from '@/components/ui/DataTable/ColumnHeader'

import { IDeployment } from '@/types/deployment/deployment.interface'

import formatId from '@/utils/formatId'

import CreateOrUpdateDeployment from './CreateOrUpdate'

export const columns: ColumnDef<IDeployment>[] = [
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Id' />
    },
    cell: ({ row }) => {
      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.id}`)
            toast.success('ID Copied to clipboard')
          }}
        >
          {formatId(row.original.id)}
        </button>
      )
    }
  },
  {
    accessorKey: 'version',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Version' />
    },
    cell: ({ row }) => {
      return <p>v{row.original.version}</p>
    }
  },
  {
    accessorKey: 'href',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Link' />
    },
    cell: ({ row }) => {
      if (!row.original.href) {
        return <p className='text-foreground/50'>-</p>
      }

      return (
        <button
          onClick={() => {
            navigator.clipboard.writeText(`${row.original.href}`)
            toast.success('Link Copied to clipboard')
          }}
          className='text-foreground/50'
        >
          Press to copy
        </button>
      )
    }
  },
  {
    accessorKey: 'type',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Type' />
    },
    cell: ({ row }) => {
      return <p>{row.original.type}</p>
    }
  },
  {
    accessorKey: 'description',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Description' />
    },
    cell: ({ row }) => {
      return <p className='text-foreground/50'>{row.original.description}</p>
    }
  },
  {
    accessorKey: 'files',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Files' />
    },
    cell: ({ row }) => {
      if (row.original.files && row.original.files.length > 0) {
        return (
          <div className='flex flex-col gap-1'>
            {row.original.files.map((file, index) => (
              <Link
                key={index}
                to={file.href}
                target='_blank'
                rel='noreferrer'
                className='text-foreground/50'
              >
                {file.key}
              </Link>
            ))}
          </div>
        )
      }
      return <p className='text-foreground/50'>-</p>
    },
    enableSorting: false
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='Added At' />
    },
    cell: ({ row }) => {
      const date = new Date(row.original.created_at)
      if (date.toString() === 'Invalid Date') {
        return <p className='text-foreground/50'>-</p>
      }

      return <p className='text-foreground/50'>{format(date, 'yyyy.MM.dd')}</p>
    }
  },
  {
    accessorKey: 'actions',
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} name='' />
    },
    cell: ({ row }) => {
      return (
        <div className='flex w-fit flex-row gap-1 border-l-2 border-border pl-4'>
          <CreateOrUpdateDeployment initial={row.original} />
          <MutationActionButton
            url={`/admin/deployment/${row.original.id}`}
            description='Are you sure you want to delete this deployment?'
            permissionSlug='deployments_delete'
            successMessage='Deployment deleted successfully'
            errorMessage='Failed to delete deployment'
          />
        </div>
      )
    },
    enableSorting: false
  }
]
