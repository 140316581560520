import Page from '@/components/Page'
import { DataTable } from '@/components/ui/DataTable'

import CreateOrUpdateMember from './CreateOrEditMember'
import { ORGANIZATION_TABS } from '@/constants/tabs'
import { columns } from '@/pages/Organization/TeamMembers/columns'

const TeamMembersPage: React.FC = () => {
  return (
    <Page
      permissionSlug='organization_view'
      pageTitle='Configurations'
      tabs={ORGANIZATION_TABS}
      header={<CreateOrUpdateMember url='/admin/admins' />}
    >
      <DataTable
        url='admin/admins'
        totalText='members'
        columns={columns}
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}

export default TeamMembersPage
