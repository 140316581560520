import MetricsCards from '.'
import { useQuery } from '@tanstack/react-query'

import {
  IAccountPageMetrics,
  IMetricCard
} from '@/types/metrics/metrics.interface'

import api from '@/api'
import { formatInteger } from '@/utils'

const formatUsersMetrics = (data: IAccountPageMetrics) => {
  return [
    {
      title: 'With Avatar',
      value: formatInteger(data.totalUsersWithAvatar.count),
      subtitle: `(${data.totalUsersWithAvatar.percentage}%)`
    },
    {
      title: 'KYC Verified',
      value: formatInteger(data.totalUsersWithKycVerified.count),
      subtitle: `(${data.totalUsersWithKycVerified.percentage}%)`
    },
    {
      title: 'Created in 24h',
      value: formatInteger(data.totalUsersCreatedAtLastDay.count),
      subtitle: `(${data.totalUsersCreatedAtLastDay.percentage}%)`
    },
    {
      title: 'Created in 30d',
      value: formatInteger(data.totalUsersCreatedAtLastMonth.count),
      subtitle: `(${data.totalUsersCreatedAtLastMonth.percentage}%)`
    }
  ] as IMetricCard[]
}

const UsersMetrics: React.FC = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['users-metrics'],
    queryFn: async () =>
      await api.get<IAccountPageMetrics>('/admin/users/metrics'),
    enabled: true,
    select: res => formatUsersMetrics(res.data)
  })

  return (
    <MetricsCards
      isError={isError}
      isLoading={isLoading}
      data={data}
      cardsConfig={{ count: 4 }}
    />
    // <div className='grid grid-cols-1 gap-1 md:grid-cols-2 md:gap-2 xl:grid-cols-4 xl:gap-6'>
    //   <UserMetricItem
    //     label='Has avatar'
    //     count={data?.totalUsersWithAvatar.count || 0}
    //     percentage={data?.totalUsersWithAvatar.percentage || 0}
    //     isLoading={isLoading}
    //     error={isError ? 'Failed to fetch users metrics' : undefined}
    //   />
    //   <UserMetricItem
    //     label="Is KYC'd"
    //     count={data?.totalUsersWithKycVerified.count || 0}
    //     percentage={data?.totalUsersWithKycVerified.percentage || 0}
    //     isLoading={isLoading}
    //     error={isError ? 'Failed to fetch users metrics' : undefined}
    //   />
    //   <UserMetricItem
    //     label='Created in last day'
    //     count={data?.totalUsersCreatedAtLastDay.count || 0}
    //     percentage={data?.totalUsersCreatedAtLastDay.percentage || 0}
    //     isLoading={isLoading}
    //     error={isError ? 'Failed to fetch users metrics' : undefined}
    //   />
    //   <UserMetricItem
    //     label='Created in last month'
    //     count={data?.totalUsersCreatedAtLastMonth.count || 0}
    //     percentage={data?.totalUsersCreatedAtLastMonth.percentage || 0}
    //     isLoading={isLoading}
    //     error={isError ? 'Failed to fetch users metrics' : undefined}
    //   />
    // </div>
  )
}

export default UsersMetrics
