import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

import ProtectedComponent from '@/components/Protected'
import MutationActionButton from '@/components/features/MutationActionButton'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field, { ErrorMessage } from '@/components/ui/Field'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue
} from '@/components/ui/Select'

import {
  DeploymentType,
  ICreateOrUpdateDeployment,
  IDeployment
} from '@/types/deployment/deployment.interface'

import { errorCatch } from '@/api/error'

import CreateApk from './CreateApk'
import api from '@/api'

interface Props {
  initial?: IDeployment
}

const CreateOrUpdateDeployment: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset
  } = useForm<ICreateOrUpdateDeployment>({
    mode: 'onChange',
    defaultValues: initial || {
      version: '',
      type: undefined,
      href: ''
    }
  })

  useEffect(() => {
    reset(
      initial || {
        version: '',
        type: undefined,
        href: ''
      }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-deployment' : 'add-deployment'],
    mutationFn: (data: ICreateOrUpdateDeployment) =>
      api(initial ? `/admin/deployment/${initial.id}` : '/admin/deployment', {
        method: initial ? 'PATCH' : 'POST',
        data
      }),
    onSuccess: () => {
      toast.success(
        `Deployment ${initial ? 'updated' : 'created'} successfully!`
      )
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          `An error occurred while ${initial ? 'updating' : 'creating'} deployment`
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateDeployment> = async data =>
    mutate(data)

  const isApk = watch('type') === DeploymentType.APK

  return (
    <ProtectedComponent
      permissionSlug={initial ? 'deployments_update' : 'deployments_create'}
    >
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={initial ? 'edit' : 'create'}>
            {initial ? 'Edit' : 'Add Deployment'}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {initial ? 'Edit deployment' : 'Add IOS OR Android deployment'}
            </DialogTitle>
            <DialogDescription>
              {initial
                ? 'Edit the deployment details'
                : 'Add a new deployment for IOS OR Android'}
            </DialogDescription>
          </DialogHeader>
          <form
            className='flex w-full flex-col gap-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              title='Version'
              placeholder='Version'
              {...register('version', {
                required: 'Version is required',
                maxLength: {
                  value: 50,
                  message: 'Version should be less than 50 characters'
                },
                minLength: {
                  value: 3,
                  message: 'Version should be at least 3 characters'
                },
                pattern: {
                  value: /^\d+\.\d+\.\d+$/,
                  message: 'Version should be in the format of 1.1.1'
                }
              })}
              error={errors.version?.message}
            />
            {!isApk && (
              <>
                <Controller
                  control={control}
                  name='type'
                  rules={{
                    required: 'Type is required'
                  }}
                  render={({ field }) => (
                    <div className='mt-2 w-full'>
                      <label className='text-lg font-bold  text-white'>
                        Type
                      </label>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger className='w-full justify-between border-2 !px-5 !py-8 !font-bold dark:border-gray500/20 dark:bg-gray800'>
                          <SelectValue placeholder='Select Type' />
                        </SelectTrigger>
                        <SelectContent side='top' className='z-[60] w-full'>
                          <SelectGroup>
                            <SelectLabel>Types</SelectLabel>
                            <SelectSeparator />
                            <SelectItem value={DeploymentType.ANDROID}>
                              {DeploymentType.ANDROID}
                            </SelectItem>
                            <SelectItem value={DeploymentType.IOS}>
                              {DeploymentType.IOS}
                            </SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <ErrorMessage error={errors.type?.message} />
                    </div>
                  )}
                />
                <Field
                  title='Href'
                  placeholder='Href'
                  {...register('href', {
                    required: 'Href is required',
                    maxLength: {
                      value: 255,
                      message: 'Href should be less than 255 characters'
                    },
                    minLength: {
                      value: 3,
                      message: 'Href should be at least 3 characters'
                    },
                    pattern: {
                      value: /^https?:\/\/.*/,
                      message: 'Href should be a valid URL'
                    }
                  })}
                  error={errors.href?.message}
                />
              </>
            )}
            <Field
              title='Description'
              placeholder='Description'
              className='!mt-0'
              {...register('description', {
                maxLength: {
                  value: 255,
                  message: 'Description should be less than 255 characters'
                },
                minLength: {
                  value: 3,
                  message: 'Description should be at least 3 characters'
                }
              })}
              error={errors.description?.message}
            />
            {isApk && (
              <>
                Files:
                {initial?.files.map(file => (
                  <div key={file.id} className='flex flex-row justify-between'>
                    <Link
                      to={file.href}
                      target='_blank'
                      rel='noreferrer'
                      className='text-foreground/50'
                    >
                      {file.key}
                    </Link>
                    <MutationActionButton
                      url={`/admin/deployment/${initial.id}/apk/${file.id}`}
                      description='Are you sure you want to delete this file?'
                      permissionSlug='deployments_delete'
                      successMessage='File deleted successfully'
                      errorMessage='Failed to delete file'
                    />
                  </div>
                ))}
                <CreateApk deploymentId={initial?.id} />
              </>
            )}

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : initial ? 'Update' : 'Create'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default CreateOrUpdateDeployment
