import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import AccountSelect from '@/components/features/AccountSelect'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import { ErrorMessage } from '@/components/ui/Field'

import { IAddAccountToGroup } from '@/types/account-groups/account-group.interface'

import api from '@/api'

export const AddToGroup = ({ groupId }: { groupId: string }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['addAccountsToAccountGroup'],
    mutationFn: async (data: IAddAccountToGroup) => {
      const resp = await api.post(`/admin/account-groups/${groupId}/add`, {
        accountIds: [data.account_id]
      })

      return resp.data
    },
    onSuccess: async () => {
      reset()
      queryClient.invalidateQueries()
      queryClient.refetchQueries()
      setIsOpen(false)
      toast.success('User successfully added to group', {
        id: 'account-groups'
      })
    },
    onError: error => {
      toast.error(error.message, {
        id: 'account-groups'
      })
    }
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<IAddAccountToGroup>({
    defaultValues: {
      account_id: undefined
    }
  })

  const onSubmit = (data: IAddAccountToGroup) => {
    mutate(data)
  }

  return (
    <ProtectedComponent permissionSlug={'account_groups_update'}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={'create'}>Add User</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add User to Group</DialogTitle>
            <DialogDescription>
              Add a user to the selected account group
            </DialogDescription>
          </DialogHeader>
          <form
            className='flex w-full flex-col gap-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name='account_id'
              control={control}
              rules={{ required: 'Account is required' }}
              render={({ field }) => (
                <label className='flex flex-col gap-2'>
                  <p className='font-semibold'>Account</p>
                  <AccountSelect
                    accountId={field.value}
                    setAccountId={field.onChange}
                  />
                  <ErrorMessage error={errors?.account_id?.message} />
                </label>
              )}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}
