import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field from '@/components/ui/Field'

import { IAddReferral } from '@/types/account/referral.interface'

import api from '@/api'

interface Props {
  referId: string
}

const AddReferral: React.FC<Props> = ({ referId }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationKey: ['add-referral'],
    mutationFn: async (data: IAddReferral) => {
      const resp = await api.put(`/admin/refer/${referId}/referrals`, data)

      return resp.data
    },
    onSuccess: async () => {
      reset()
      queryClient.invalidateQueries()
      queryClient.refetchQueries()
      setIsOpen(false)
      toast.success('Referral added')
    },
    onError: error => {
      toast.error(error.message || 'An error occurred')
    }
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<IAddReferral>({
    defaultValues: {
      email: undefined
    }
  })

  const onSubmit = (data: IAddReferral) => {
    mutate(data)
  }

  return (
    <ProtectedComponent permissionSlug={'refer_update'}>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={'create'}>Add Referral</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add Referral</DialogTitle>
            <DialogDescription>
              Add an email as a referral to this account
            </DialogDescription>
          </DialogHeader>
          <form
            className='flex w-full flex-col gap-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              title='Email'
              placeholder='Email'
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Invalid email address'
                }
              })}
              error={errors?.email?.message}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default AddReferral
