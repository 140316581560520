import Page from '@/components/Page'
import ProtectedComponent from '@/components/Protected'
import { DataTable } from '@/components/ui/DataTable'

import CreateMany from './CreateMany'
import CreateOrUpdate from './CreateOrUpdate'
import { columns } from './columns'
import { USERS_TABS } from '@/constants/tabs'

const UsernameBlacklistPage: React.FC = () => {
  return (
    <Page
      permissionSlug='username_blacklist_view'
      pageTitle='Username Blacklist'
      header={
        <ProtectedComponent permissionSlug='username_blacklist_update'>
          <div className='flex flex-row gap-2'>
            <CreateOrUpdate />
            <CreateMany />
          </div>
        </ProtectedComponent>
      }
      tabs={USERS_TABS}
    >
      <DataTable
        columns={columns}
        url='/admin/username-blacklist'
        totalText='usernames'
      />
    </Page>
  )
}

export default UsernameBlacklistPage
