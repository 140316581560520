import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import ProtectedComponent from '@/components/Protected'
import { Button } from '@/components/ui/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/Dialog'
import Field, { ErrorMessage } from '@/components/ui/Field'
import AnimatedLoader from '@/components/ui/Loader'
import Switch from '@/components/ui/Switch'

import { ICreateOrUpdateVideo, IVideo } from '@/types/learn/video.interface'

import {
  getVideoDataByUrl,
  getVideoId,
  isValidYouTubeUrl
} from '@/utils/youtube'

import { errorCatch } from '@/api/error'

import api from '@/api'
import CategoriesSelect from '@/pages/Learn/Videos/Autocomplete/CategoriesSelect'
import CreatorsSelect from '@/pages/Learn/Videos/Autocomplete/CreatorsSelect'

interface Props {
  initial?: IVideo
  url: string
}

const CreateOrUpdateVideo: React.FC<Props> = ({ initial, url }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm<ICreateOrUpdateVideo>({
    mode: 'onChange',
    defaultValues: initial
      ? {
          title: initial.title,
          video_id: initial.video_id,
          video_url: initial.video_url,
          video_upload_timestamp: initial.video_upload_timestamp,
          creators: initial.creators ? initial.creators.map(c => c.id) : [],
          categories: initial.categories
            ? initial.categories.map(c => c.id)
            : [],
          status: initial.status
        }
      : {
          title: '',
          video_id: '',
          video_url: '',
          video_upload_timestamp: '',
          categories: [],
          creators: [],
          status: 1
        }
  })

  useEffect(() => {
    reset(
      initial
        ? {
            title: initial.title,
            video_id: initial.video_id,
            video_url: initial.video_url,
            video_upload_timestamp: initial.video_upload_timestamp,
            creators: initial.creators ? initial.creators.map(c => c.id) : [],
            categories: initial.categories
              ? initial.categories.map(c => c.id)
              : [],
            status: initial.status
          }
        : {
            title: '',
            video_id: '',
            video_url: '',
            video_upload_timestamp: '',
            categories: [],
            creators: [],
            status: 1
          }
    )
  }, [initial, reset])

  const videoUrl = watch('video_url')

  const { data, isLoading } = useQuery({
    queryKey: ['video-data', videoUrl],
    queryFn: async () => {
      const resp = await getVideoDataByUrl(videoUrl)

      if (!resp || !resp.items || resp.items.length === 0) {
        return null
      }

      return {
        thumbnail: resp.items[0].snippet.thumbnails.medium.url,
        publishedAt: resp.items[0].snippet.publishedAt,
        videoId: resp.items[0].id
      }
    },
    enabled: isOpen && !!videoUrl && isValidYouTubeUrl(videoUrl),
    select: data => data
  })

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-video' : 'add-video', initial?.id],
    mutationFn: async (video: ICreateOrUpdateVideo) =>
      api(url, {
        method: initial ? 'PATCH' : 'POST',
        data: {
          ...video,
          video_id: data?.videoId || getVideoId(video.video_url),
          video_upload_timestamp: data?.publishedAt || new Date().toISOString(),
          categories: video.categories,
          creators: video.creators,
          status: Number(video.status)
        }
      }),
    onSuccess: () => {
      toast.success(
        initial ? 'Video updated successfully' : 'Video added successfully'
      )
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        initial
          ? 'Failed to update video. '
          : 'Failed to add video. ' + errorMessage
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateVideo> = async data =>
    mutate(data)

  return (
    <ProtectedComponent
      permissionSlug={initial ? 'learn_update' : 'learn_create'}
    >
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant={initial ? 'edit' : 'create'}>
            {initial ? 'Edit' : 'Create'}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{initial ? 'Edit Video' : 'Create Video'}</DialogTitle>
            <DialogDescription>
              {initial ? 'Edit the video details' : 'Create a new video'}
            </DialogDescription>
          </DialogHeader>
          <form
            className='mt-4 flex flex-col gap-5'
            onSubmit={handleSubmit(onSubmit)}
          >
            {data && (
              <div className='relative flex flex-1 flex-col items-center justify-between gap-[10px] rounded-[10px] border-2 border-gray500/20 bg-gray800 p-[20px]'>
                <span className='text-[12px] font-bold leading-[15px] text-white'>
                  Thumbnail Image
                </span>
                <div
                  className='h-[100px] w-[140px] overflow-hidden rounded-[10px] bg-cover bg-center'
                  style={{
                    backgroundImage: `url(${data.thumbnail})`
                  }}
                />
                {isLoading && <AnimatedLoader />}
              </div>
            )}
            <div className='flex flex-col gap-2'>
              <Controller
                name='creators'
                control={control}
                render={({ field }) => {
                  return (
                    <CreatorsSelect
                      creators={field.value}
                      setCreators={field.onChange}
                    />
                  )
                }}
              />
              <ErrorMessage error={errors?.creators?.message} />
            </div>
            <Field
              placeholder='Video URL'
              {...register('video_url', { required: 'Video URL is required' })}
              className='!mt-0'
              error={errors.video_url?.message}
            />
            <Field
              placeholder='Video Title'
              {...register('title', { required: 'Title is required' })}
              className='!mt-0'
              error={errors.title?.message}
            />
            <div className='flex flex-col gap-2'>
              <Controller
                name='categories'
                control={control}
                render={({ field }) => {
                  return (
                    <CategoriesSelect
                      initial={initial?.categories.map(c => ({
                        label: c.name,
                        value: c.id
                      }))}
                      categories={field.value}
                      setCategories={field.onChange}
                    />
                  )
                }}
              />
              <ErrorMessage error={errors?.categories?.message} />
            </div>
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <Switch
                  title='Visibility'
                  checked={field.value === 1}
                  onCheckedChange={(checked: boolean) => {
                    field.onChange(checked ? 1 : 0)
                  }}
                />
              )}
            />
            <Button
              onClick={() => handleSubmit(onSubmit)()}
              disabled={isPending}
              size={'lg'}
            >
              {isPending ? 'Loading...' : 'Confirm'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </ProtectedComponent>
  )
}

export default CreateOrUpdateVideo
